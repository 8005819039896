@media (min-width: 1300px){
  .container{max-width: 1280px;}
}
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  float: left;
}
.clearfix{
  clear:both;
}
* {
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;
}
a{
  text-decoration:none;
  transition:all 500ms ease;
  -moz-transition:all 500ms ease;
  -webkit-transition:all 500ms ease;
  -ms-transition:all 500ms ease;
  -o-transition:all 500ms ease;
}
a:hover{
  text-decoration:none;
  outline:none;
}
input,button,select,textarea{
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 16px;
}
ul,li{
  list-style:none;
  padding:0px;
  margin:0px; 
}
input{
  transition: all 500ms ease;
}
button:focus,
input:focus,
textarea:focus{
  outline: none;
  box-shadow: none;
  transition: all 500ms ease;
}
p{
  position: relative;
  font-size: 16px;
  line-height: 30px;
  color: #444444;
  font-family: 'Lato', sans-serif;
  margin-bottom: 0px;
  font-style: normal;
  transition: all 500ms ease;
}
h1,h2,h3,h4,h5,h6{
  position:relative;
  font-family: "Prata", serif;
  font-weight: 400;
  color: #161616;
  margin: 0px;
  transition: all 500ms ease;
}
body{
  font-family: 'Lato', sans-serif;
  background-color: white;
  overflow-x: hidden;
  border-top: 4px solid #ff4747;
}
body::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

body::-webkit-scrollbar
{
  width: 7px;
	background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb
{
  background-color: #ff4747;
	background-image: -webkit-linear-gradient(45deg,
	                                          rgba(255, 255, 255, .2) 25%,
											  transparent 25%,
											  transparent 50%,
											  rgba(255, 255, 255, .2) 50%,
											  rgba(255, 255, 255, .2) 75%,
											  transparent 75%,
											  transparent)
}

/* --------------------- Menu Section ------------------------------ */
.App{
  background-repeat: repeat-x;
    background-image: url(./Img/download.png);
    background-position: 0 100%;
}
.header-middle{
  position: relative;
  left: 0px;
  top: 0px;
  z-index: 999;
  width: 100%;
}
.outer-box {
  width: 100%;
}
.header-middle img {
  max-width: 217px;
  padding: 14px 0 14px 0;
}
ul.main-menu>li.menu-item>a {
margin-left: 10px;
margin-right: 10px;
padding-top: 25px;
padding-bottom: 25px;
font-size: 17px;
text-transform: capitalize;
font-weight: 500;
color: #000000;
letter-spacing: 1px;
}
.menu-item {
  list-style: none;
}
.menu-item a {
  color: #ffffff;
  font-size: 17px;
}
.menu-item a:hover{
  color: #ff4747;
}
.is-active {
  color: #ff4747!important;
}
.cols {
  color: #ff4747;
}
ul.main-menu>li.menu-item .aaa2{
  color: #0082ff;
  font-weight: 700;
}
@media (min-width: 992px){
 ul.main-menu {
   display: flex;
   position: relative;
   list-style: none;
   margin: 23px 0 0 0;
   padding: 0;
   float: right;
  }
  ul.main-menu>li.menu-item:first-child { padding-left: 0;}
  ul.main-menu>li.menu-item>a:last-child {margin-right: 0; }
  ul.main-menu>li.menu-item>a { margin-left: 13px;margin-right: 13px;}
  ul.main-menu>li .sub-menu { padding: 30px;  }
  ul.main-menu>li .sub-menu {
    width: 227px;
    position: absolute;
    top: 100%;
    left: 0;
    background: #ffffff;
    border-top: 4px solid #ff4747;
    margin-top: 17px;
    opacity: 0;
    pointer-events: none;
    box-shadow: 1px 8px 13px #c5c5c5;
     }
     ul.main-menu>li .sub-menu .menu-item a {
      color: #000000;
      font-size: 17px;
      text-transform: capitalize;
      margin-bottom: 17px;
      float: left;
     }
     ul.main-menu>li .sub-menu .menu-item a:hover {
      color: #ff4747;
     }
     ul.main-menu>li .sub-menu li {
         margin-bottom: 15px;
         color: #333;
         text-transform: capitalize;
         font-weight: 400;
         font-size: 14px;
         margin-bottom: 10px;
     }
     ul.main-menu .has-children:hover>.sub-menu { pointer-events: visible;  opacity: 1; }
     ul.main-menu .has-children:hover {z-index: 200;}
ul.main-menu .has-children {position: relative;}
}  
@media (max-width: 991px){ 
.menuq1{display:none;}
.menuq2{ display:block!important;}
.res5c {
  display: table!important;
  float: none;
  background-color: #ff4747;
  color: #fff;
  padding: 7px;
  border-radius: 5px;
  margin: 10px auto;
}
}
.has-children .svg-inline--fa.fa-w-14 {
    width: 10px;
    margin-left: 3px;
    vertical-align: -6px;
    color: #403d3d;
}
@media (min-width:992px) and (max-width:1200px){
  ul.main-menu>li.menu-item>a {margin-left: 10px!important;}
}
/* --------------------- slider Section ------------------------------ */

.topslider{
  background-image: url('./Img/bg-choose-bike.jpg');
  background-repeat: no-repeat;
  padding: 60px 0 60px 0;
  background-position: center;
  position: relative;
}
.topslider img{
  box-shadow: 0 8px 16px -6px, 0 0 16px -6px;
  color: #000;
  border-radius: 7px;
}
.topslider h1 {
  color: #5A3733;
  text-align: center;
  line-height: 1.2em;
  font-weight: 900;
  font-family: 'Signika', serif;
  margin-bottom: 17px;
  font-size: 47px;
} 
.topslider ul{
  padding-top: 37px;
  padding-bottom: 37px;
}
.topslider .BrainhubCarouselItem{
  padding-top: 37px;
  padding-bottom: 37px;
}
.menu-item .svg-inline--fa.fa-w-16 {
  color: #ff4747;
}
/* --------------------- slider trailer  Section ------------------------------ */
.topslider2{
  display: block;
  position: relative;
  padding:97px 0px 97px 0px;
  background-image: url('./Img/flowers_2.png');
  background-position: 109% 7% !important;
  background-repeat: no-repeat;
}
.topslider2:before{
  content: "";
  position: absolute !important;
  background-image: url('./Img/flowers_1.png');
  background-repeat: no-repeat;
  width: 67%;
  height: 67%;
  z-index: 0;
  bottom: -83px;
  left: 0;
}
.topslider2 h1 {
  color: #5A3733;
  text-align: center;
  line-height: 1.2em;
  font-weight: 900;
  font-family: 'Signika', serif;
  font-size: 47px;
} 
.ltx-bees > div {
  background-position: -11% 100% !important;
}
.ltx-overlay-hex {
  background-color: #F8F7F1;
  content: "";
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}
.ltx-overlay-hex:after {
  content: "";
  background-image: url('./Img/sss.png');
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  display: block;
  zoom: 1;
  filter: alpha(opacity=5);
  -webkit-opacity: 0.05;
  -moz-opacity: 0.05;
  opacity: 0.05;
}
.wpb_single_image.vc_align_right {
  text-align: right;
  margin-bottom: 35px;
  position: absolute;
  right: 137px;
  top: 47px;
}
.wpb_single_image .vc_figure {
  display: inline-block;
  vertical-align: top;
  margin: 0;
  max-width: 100%;
}
.wpb_single_image .vc_single_image-wrapper {
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
}
 .ltx-bee-floating-right.ltx-bee-floating-right img {
  position: relative;
  margin-top: 12px;
  animation-name: floating_2;
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
  animation-iteration-count: infinite;
}

.wpb_single_image img {
  height: auto;
  max-width: 100%;
  vertical-align: top;
}
@keyframes floating_1 {
  0% {
    margin-top: -60px;
  }
  50% {
    margin-top: -30px;
  }
}
@keyframes floating_2 {
  0% {
    margin-top: 12px;
  }
  50% {
    margin-top: 30px;
  }
}
.videosss2 {
  width: 777px;
  height: 437px;
  margin: 27px auto 17px;
  display: block;
  position: relative;
  box-shadow: 7px 7px 17px -15px, 0 0 7px -17px;
  color: #000;
  border-radius: 10px;
  border: 7px solid white;
}



/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.BrainhubCarousel__arrows {
  background-color: #ff4747!important;
}
.BrainhubCarousel__arrows:disabled {
  background-color: #ccc!important;
}
/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.media-container:hover .book {
  -webkit-transform: rotateX(5deg) rotateY(-50deg);
  transform: rotateX(5deg) rotateY(-50deg);
}
.app {
  --thickness: 20px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-perspective: 1500px;
  perspective: 1500px;
}
.app[data-current-media="book"] .book-wrapper {
  display: block;
}
.book {
  position: relative;
  z-index: 1;
  max-width: 237px;
  max-height: 367px;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: -webkit-transform 1s ease;
  transition: -webkit-transform 1s ease;
  transition: transform 1s ease;
  transition: transform 1s ease, -webkit-transform 1s ease;
  -webkit-transform: rotateX(0deg) rotateY(0deg);
  transform: rotateX(0deg) rotateY(0deg);
}
.book__front {
  border-radius: 5px;
  overflow: hidden;
  -webkit-transform: translateZ(calc(var(--thickness)));
  transform: translateZ(calc(var(--thickness)));
}
.book__paper {
  height: 98%;
  width: calc(var(--thickness) * 2);
  background: white;
  position: absolute;
  top: 1%;
  right: 0;
  background: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.3)), to(rgba(255, 255, 255, 0))), repeating-linear-gradient(90deg, white, #e2e2e2 1px, white 3px, #9a9a9a 1px);
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.3), rgba(255, 255, 255, 0)), repeating-linear-gradient(90deg, white, #e2e2e2 1px, white 3px, #9a9a9a 1px);
  -webkit-transform: rotateY(90deg) translateX(calc(var(--thickness) / 7)) translateZ(calc(var(--thickness) / 1.2));
  transform: rotateY(90deg) translateX(calc(var(--thickness) / 7)) translateZ(calc(var(--thickness) / 1.2));
}
.book__back {
  width: 100%;
  height: 99.4%;
  position: absolute;
  top: 0;
  background: #356143;
  border-radius: 5px;
  overflow: hidden;
  -webkit-transform: translateZ(calc(-1 * var(--thickness)));
  transform: translateZ(calc(-1 * var(--thickness)));
}
.book-shadow {
  position: absolute;
  width: 167px;
  height: calc(33px + var(--thickness));
  background: radial-gradient(70% 85%, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%);
  -webkit-transform: rotateX(90deg) rotateZ(30deg);
  transform: rotateX(90deg) rotateZ(30deg);
  -webkit-transition: -webkit-transform 1s ease;
  transition: -webkit-transform 1s ease;
  transition: transform 1s ease;
  transition: transform 1s ease, -webkit-transform 1s ease;
  -webkit-filter: blur(32px);
  filter: blur(32px);
}
.book-wrapper img {
  max-width: 217px;
  width: auto;
  height: 302px;
  overflow: hidden;
  object-fit: cover;
  object-position: center top;
  margin: 0 auto;
  display: block;
}
.ttt:hover .heading.price{
 opacity: 1;
}
.ttt h2{
  margin-bottom: 0!important;
}
.book-wrappertitlss2 {
  font-size: 18px;
  color: #fd6500;
  margin: 10px 0 0 0;
}
.book__front:before {
  content: "";
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
  background: url('./Img/sss2.png') repeat-y 0;
  background-size: 100% auto;
  opacity: 0.6;
}

/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.topslider .app:hover .heading.price {

  opacity: 1;
}
.heading.align-center {
  text-align: center;
}
.heading.price {

  opacity: 0;
  display: inline-block;
  padding: 17px 27px;
  -webkit-border-radius: 50%;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 50%;
  -moz-background-clip: padding;
  border-radius: 50%;
  background-clip: padding-box;
  position: absolute;
  white-space: nowrap;
  right: -17px;
  top: 32px;
  z-index: 17;
    
}
.heading.price:after, .heading.price:before {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  padding: 30px 48px;
  content: "";
  background-color: #FFD832;
  -webkit-border-radius: 50%;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 50%;
  -moz-background-clip: padding;
  border-radius: 50%;
  background-clip: padding-box;
  -webkit-transform: rotate(20deg);
  -moz-transform: rotate(20deg);
  -o-transform: rotate(20deg);
  -ms-transform: rotate(20deg);
  transform: rotate(20deg);
}
.heading.price:after {
  background-color: #f64442;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
.heading.color-white .header {
  color: #fff;
  z-index: 17;
  font-weight: 900;
  font-family: 'Signika', serif;
}
/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.topslider3 {
  position: relative;
  background-size: cover;
  padding: 67px 0 67px 0;
  background-position: center;
  width: 100%;
  float: left;
}
.topslider3 h1 {
  color: #5A3733;
  text-align: center;
  line-height: 1.2em;
  font-weight: 900;
  font-family: 'Signika', serif;
  font-size: 47px;
  margin-bottom: 37px;
} 
.topslider3 h2{
  font-family: 'Signika', serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 33px;
  margin-bottom: 17px;
}
.booksrec4{
  width: 300px;
  margin: 0 auto;
}
.booksrec4 img {
  max-height: 227px;
  overflow: hidden;
  margin: 0 auto;
  display: block;
  box-shadow: -3px 1px 6px #5f5d5d;
  border-radius: 10px;
}
.desc{
  max-height: 300px;
  overflow: hidden;
  margin-bottom: 37px;
  font-family: Georgia, serif;
}
.audsss {
  width: 100%;
}
.cccgggsss {
  height: 27px;
  overflow: hidden;
  text-align: center;
  color: #ff4747;
  margin: 17px auto 7px;
  display: block;
}
.rectisss {
  padding: 0;
  display: block;
  text-transform: capitalize;
  overflow: hidden;
  font-weight: 300;
  margin: 0 auto 37px;
  height: 64px;
  text-align: center;
  font-size: 18px;
  line-height: 30px;
}
.product-card .product-header {
  padding: 0 15px;
}
/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.aaa3 {
  position: relative;
  box-shadow: 0 3px 24px rgba(0,0,0,.04);
  border-radius: 7px;
  padding: 37px 37px 12px 37px;
  background-color: #fff;
  border: 1px solid #f0f2f3;
  margin-bottom: 27px;
}
.booksrec2 img{
  margin: 17px auto;
  display: block;
  transition: .3s;
}
.aaa3 .booksrec2:hover img{
  transform: scale(1.1) rotate(0deg);
}
.aaa3 h6 {
  font-family: 'Signika', serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 33px;
  text-align: center;
  margin: 0 auto;
  display: block;
}
.aaa3 .desc {
  max-height: 197px;
  margin-bottom: 37px;
  text-align: center;
}
/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.aaa img {
  width: 53px;
  height: 77px;
  border-radius: 3px;
  box-shadow: 0 7px 7px -5px, 0 0 7px -6px;
  color: #a9a1a1;
  margin: 0 auto;
  display: block;
}
.aaa2{
  max-height: 60px;
  overflow: hidden;
}
.topslider .aaa .BrainhubCarouselItem {
  padding-top: 0;
}
.clearfix{
  clear:both;
}
/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.book2 {
  position: relative;
  width: 160px;
  height: 220px;
  -webkit-perspective: 1000px;
  -moz-perspective: 1000px;
  perspective: 1000px;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  margin-top: 30%;
  cursor: pointer;
  z-index: 111;
  pointer-events: none;
  transform: scale(1.6);
  pointer-events: auto;
}

.hardcover_front li:first-child {
  background-color: #eee;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.hardcover_front li:last-child {
  background: #fffbec;
}

.hardcover_back li:first-child {
  background: #fffbec;
}

.hardcover_back li:last-child {
  background: #fffbec;
}

.book_spine li:first-child {
  background: #eee;
}

.book_spine li:last-child {
  background: #333;
}
::before, ::after {
  content: "";
}
.hardcover_front li:first-child:after,
.hardcover_front li:first-child:before,
.hardcover_front li:last-child:after,
.hardcover_front li:last-child:before,
.hardcover_back li:first-child:after,
.hardcover_back li:first-child:before,
.hardcover_back li:last-child:after,
.hardcover_back li:last-child:before,
.book_spine li:first-child:after,
.book_spine li:first-child:before,
.book_spine li:last-child:after,
.book_spine li:last-child:before {
  background: #999;
}
.page > li {
  background: -webkit-linear-gradient(to left, #fcfafa 0%, #f7f3ef 100%);
  background: -moz-linear-gradient(to left, #fcfafa 0%, #f7f3ef 100%);
  background: -ms-linear-gradient(to left, #fcfafa 0%, #f7f3ef 100%);
  background: linear-gradient(to left, #fcfafa 0%, #f7f3ef 100%);
  box-shadow: inset 0px -1px 2px rgba(50, 50, 50, 0.1), inset -1px 0px 1px rgba(150, 150, 150, 0.2);
  border-radius: 0px 5px 5px 0px;
}

.hardcover_front {
  -webkit-transform: rotateY(-34deg) translateZ(8px);
  -moz-transform: rotateY(-34deg) translateZ(8px);
  transform: rotateY(-34deg) translateZ(8px);
  z-index: 100;
}

.hardcover_back {
  -webkit-transform: rotateY(-15deg) translateZ(-8px);
  -moz-transform: rotateY(-15deg) translateZ(-8px);
  transform: rotateY(-15deg) translateZ(-8px);
}

.page li:nth-child(1) {
  -webkit-transform: rotateY(-28deg);
  -moz-transform: rotateY(-28deg);
  transform: rotateY(-28deg);
}

.page li:nth-child(2) {
  -webkit-transform: rotateY(-30deg);
  -moz-transform: rotateY(-30deg);
  transform: rotateY(-30deg);
}

.page li:nth-child(3) {
  -webkit-transform: rotateY(-32deg);
  -moz-transform: rotateY(-32deg);
  transform: rotateY(-32deg);
}

.page li:nth-child(4) {
  -webkit-transform: rotateY(-34deg);
  -moz-transform: rotateY(-34deg);
  transform: rotateY(-34deg);
}

.page li:nth-child(5) {
  -webkit-transform: rotateY(-36deg);
  -moz-transform: rotateY(-36deg);
  transform: rotateY(-36deg);
}
.hardcover_front,
.hardcover_back,
.book_spine,
.hardcover_front li,
.hardcover_back li,
.book_spine li {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.hardcover_front,
.hardcover_back {
  -webkit-transform-origin: 0% 100%;
  -moz-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
}

.hardcover_front {
  -webkit-transition: all 0.8s ease, z-index 0.6s;
  -moz-transition: all 0.8s ease, z-index 0.6s;
  transition: all 0.8s ease, z-index 0.6s;
}

.hardcover_front li:first-child {
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-transform: translateZ(2px);
  -moz-transform: translateZ(2px);
  transform: translateZ(2px);
}

.hardcover_front li:last-child {
  -webkit-transform: rotateY(180deg) translateZ(2px);
  -moz-transform: rotateY(180deg) translateZ(2px);
  transform: rotateY(180deg) translateZ(2px);
}

.hardcover_back li:first-child {
  -webkit-transform: translateZ(2px);
  -moz-transform: translateZ(2px);
  transform: translateZ(2px);
}

.hardcover_back li:last-child {
  -webkit-transform: translateZ(-2px);
  -moz-transform: translateZ(-2px);
  transform: translateZ(-2px);
}

.hardcover_front li:first-child:after,
.hardcover_front li:first-child:before,
.hardcover_front li:last-child:after,
.hardcover_front li:last-child:before,
.hardcover_back li:first-child:after,
.hardcover_back li:first-child:before,
.hardcover_back li:last-child:after,
.hardcover_back li:last-child:before,
.book_spine li:first-child:after,
.book_spine li:first-child:before,
.book_spine li:last-child:after,
.book_spine li:last-child:before {
  position: absolute;
  top: 0;
  left: 0;
}

.hardcover_front li:first-child:after,
.hardcover_front li:first-child:before {
  width: 4px;
  height: 100%;
}

.hardcover_front li:first-child:after {
  -webkit-transform: rotateY(90deg) translateZ(-2px) translateX(2px);
  -moz-transform: rotateY(90deg) translateZ(-2px) translateX(2px);
  transform: rotateY(90deg) translateZ(-2px) translateX(2px);
}

.hardcover_front li:first-child:before {
  -webkit-transform: rotateY(90deg) translateZ(158px) translateX(2px);
  -moz-transform: rotateY(90deg) translateZ(158px) translateX(2px);
  transform: rotateY(90deg) translateZ(158px) translateX(2px);
}

.hardcover_front li:last-child:after,
.hardcover_front li:last-child:before {
  width: 4px;
  height: 160px;
}

.hardcover_front li:last-child:after {
  -webkit-transform: rotateX(90deg) rotateZ(90deg) translateZ(80px) translateX(-2px) translateY(-78px);
  -moz-transform: rotateX(90deg) rotateZ(90deg) translateZ(80px) translateX(-2px) translateY(-78px);
  transform: rotateX(90deg) rotateZ(90deg) translateZ(80px) translateX(-2px) translateY(-78px);
}

.hardcover_front li:last-child:before {
  box-shadow: 0px 0px 30px 5px #333;
  -webkit-transform: rotateX(90deg) rotateZ(90deg) translateZ(-140px) translateX(-2px) translateY(-78px);
  -moz-transform: rotateX(90deg) rotateZ(90deg) translateZ(-140px) translateX(-2px) translateY(-78px);
  transform: rotateX(90deg) rotateZ(90deg) translateZ(-140px) translateX(-2px) translateY(-78px);
}

.hardcover_back li:first-child:after,
.hardcover_back li:first-child:before {
  width: 4px;
  height: 100%;
}

.hardcover_back li:first-child:after {
  -webkit-transform: rotateY(90deg) translateZ(-2px) translateX(2px);
  -moz-transform: rotateY(90deg) translateZ(-2px) translateX(2px);
  transform: rotateY(90deg) translateZ(-2px) translateX(2px);
}

.hardcover_back li:first-child:before {
  -webkit-transform: rotateY(90deg) translateZ(158px) translateX(2px);
  -moz-transform: rotateY(90deg) translateZ(158px) translateX(2px);
  transform: rotateY(90deg) translateZ(158px) translateX(2px);
}

.hardcover_back li:last-child:after,
.hardcover_back li:last-child:before {
  width: 4px;
  height: 160px;
}

.hardcover_back li:last-child:after {
  -webkit-transform: rotateX(90deg) rotateZ(90deg) translateZ(80px) translateX(2px) translateY(-78px);
  -moz-transform: rotateX(90deg) rotateZ(90deg) translateZ(80px) translateX(2px) translateY(-78px);
  transform: rotateX(90deg) rotateZ(90deg) translateZ(80px) translateX(2px) translateY(-78px);
}

.hardcover_back li:last-child:before {
  box-shadow: 10px -1px 80px 20px #666;
  -webkit-transform: rotateX(90deg) rotateZ(90deg) translateZ(-140px) translateX(2px) translateY(-78px);
  -moz-transform: rotateX(90deg) rotateZ(90deg) translateZ(-140px) translateX(2px) translateY(-78px);
  transform: rotateX(90deg) rotateZ(90deg) translateZ(-140px) translateX(2px) translateY(-78px);
}

.book_spine {
  -webkit-transform: rotateY(60deg) translateX(-2px) translateZ(-12px);
  -moz-transform: rotateY(60deg) translateX(-2px) translateZ(-12px);
  transform: rotateY(60deg) translateX(-2px) translateZ(-12px);
  width: 16px;
  z-index: 0;
}

.book_spine li:first-child {
  -webkit-transform: translateZ(2px);
  -moz-transform: translateZ(2px);
  transform: translateZ(2px);
}

.book_spine li:last-child {
  -webkit-transform: translateZ(-2px);
  -moz-transform: translateZ(-2px);
  transform: translateZ(-2px);
}

.book_spine li:first-child:after,
.book_spine li:first-child:before {
  width: 4px;
  height: 100%;
}

.book_spine li:first-child:after {
  -webkit-transform: rotateY(90deg) translateZ(-2px) translateX(2px);
  -moz-transform: rotateY(90deg) translateZ(-2px) translateX(2px);
  transform: rotateY(90deg) translateZ(-2px) translateX(2px);
}

.book_spine li:first-child:before {
  -webkit-transform: rotateY(-90deg) translateZ(-12px);
  -moz-transform: rotateY(-90deg) translateZ(-12px);
  transform: rotateY(-90deg) translateZ(-12px);
}

.book_spine li:last-child:after,
.book_spine li:last-child:before {
  width: 4px;
  height: 16px;
}

.book_spine li:last-child:after {
  -webkit-transform: rotateX(90deg) rotateZ(90deg) translateZ(8px) translateX(2px) translateY(-6px);
  -moz-transform: rotateX(90deg) rotateZ(90deg) translateZ(8px) translateX(2px) translateY(-6px);
  transform: rotateX(90deg) rotateZ(90deg) translateZ(8px) translateX(2px) translateY(-6px);
}

.book_spine li:last-child:before {
  box-shadow: 5px -1px 100px 40px rgba(0, 0, 0, 0.2);
  -webkit-transform: rotateX(90deg) rotateZ(90deg) translateZ(-210px) translateX(2px) translateY(-6px);
  -moz-transform: rotateX(90deg) rotateZ(90deg) translateZ(-210px) translateX(2px) translateY(-6px);
  transform: rotateX(90deg) rotateZ(90deg) translateZ(-210px) translateX(2px) translateY(-6px);
}

.page,
.page > li {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.page {
  width: 100%;
  height: 98%;
  top: 1%;
  left: 3%;
  z-index: 10;
  list-style: none;
}

.page > li {
  width: 100%;
  height: 100%;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  transform-origin: left center;
  -webkit-transition-property: transform;
  -moz-transition-property: transform;
  transition-property: transform;
  -webkit-transition-timing-function: ease;
  -moz-transition-timing-function: ease;
  transition-timing-function: ease;
}

.page > li:nth-child(1) {
  -webkit-transition-duration: 0.6s;
  -moz-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.page > li:nth-child(2) {
  -webkit-transition-duration: 0.6s;
  -moz-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.page > li:nth-child(3) {
  -webkit-transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  transition-duration: 0.4s;
}

.page > li:nth-child(4) {
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.page > li:nth-child(5) {
  -webkit-transition-duration: 0.6s;
  -moz-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.page > li > span {
  text-align: center;
  margin-top: 55px;
  margin-bottom: 0;
  display: block;
}

.page > li span img {
  margin: 0 auto;
  display: block;
  width: 60px;
  height: 60px;
  overflow: hidden;
  object-fit: cover;
  -o-object-position: center right;
  object-position: center top;
}

.page > li span span {
  margin: 10px 0 0;
  text-transform: uppercase;
  font-size: 14px;
  display: block;
  color: #ff4747;
}
.book2:hover > .hardcover_front {
  -webkit-transform: rotateY(-145deg) translateZ(0);
  -moz-transform: rotateY(-145deg) translateZ(0);
  transform: rotateY(-145deg) translateZ(0);
  z-index: 0;
}

.book2:hover > .page li:nth-child(1) {
  -webkit-transform: rotateY(-30deg);
  -moz-transform: rotateY(-30deg);
  transform: rotateY(-30deg);
  -webkit-transition-duration: 1.5s;
  -moz-transition-duration: 1.5s;
  transition-duration: 1.5s;
}

.book2:hover > .page li:nth-child(2) {
  -webkit-transform: rotateY(-35deg);
  -moz-transform: rotateY(-35deg);
  transform: rotateY(-35deg);
  -webkit-transition-duration: 1.8s;
  -moz-transition-duration: 1.8s;
  transition-duration: 1.8s;
}

.book2:hover > .page li:nth-child(3) {
  -webkit-transform: rotateY(-118deg);
  -moz-transform: rotateY(-118deg);
  transform: rotateY(-118deg);
  -webkit-transition-duration: 1.6s;
  -moz-transition-duration: 1.6s;
  transition-duration: 1.6s;
}

.book2:hover > .page li:nth-child(4) {
  -webkit-transform: rotateY(-130deg);
  -moz-transform: rotateY(-130deg);
  transform: rotateY(-130deg);
  -webkit-transition-duration: 1.4s;
  -moz-transition-duration: 1.4s;
  transition-duration: 1.4s;
}

.book2:hover > .page li:nth-child(5) {
  -webkit-transform: rotateY(-140deg);
  -moz-transform: rotateY(-140deg);
  transform: rotateY(-140deg);
  -webkit-transition-duration: 1.2s;
  -moz-transition-duration: 1.2s;
  transition-duration: 1.2s;
}

.coverDesign {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

.topslider4{
  background-repeat: no-repeat;
  padding: 60px 0 60px 0;
  background-position: center;
  position: relative;
}
.topslider4 h1 {
  color: #5A3733;
  text-align: center;
  line-height: 1.2em;
  font-weight: 900;
  font-family: 'Signika', serif;
  margin-bottom: 17px;
  font-size: 47px;
} 
.topslider4 .coverDesign img {
  height: 220px;
  width: 100%;
}
.desc2 {
  height: 247px;
  overflow: hidden;
  font-size: 16px;
  line-height: 30px;
  color: #444;
  font-family: "Lato",sans-serif;
  margin-bottom: 0;
  font-style: normal;
}
.topslider4 ul{
  padding-top: 37px;
  padding-bottom: 37px;
}
.topslider4 .BrainhubCarouselItem{
  padding-top: 37px;
  padding-bottom: 37px;
}
.topslider4 h2 {
  font-family: 'Signika', serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 33px;
  margin-bottom: 17px;
}
.desc2 h1, .desc2 h2, .desc2 h3, .desc2 h4, .desc2 h5, .desc2 h6{
    position: relative;
    font-size: 18px;
    line-height: 37px;
    color: #444444;
    font-family: 'Lato', sans-serif;
    margin-bottom: 0px;
    font-style: normal;
}
.bann {
  font-size: 18px;
  color: #ff4747!important;
  text-align: left;
  margin-top: 13px;
  font-weight: 900;
  font-family: 'Signika', serif;
  margin-bottom: 17px;
}
.bosss{
  padding: 50px 27px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  box-shadow: 8px 8px 30px 0px rgba(42, 67, 113, 0.15);
  -webkit-box-shadow: 8px 8px 30px 0px rgba(42, 67, 113, 0.15);
  -moz-box-shadow: 8px 8px 30px 0px rgba(42, 67, 113, 0.15);
  color: white;
  background-color: white;
}
.title-table {
  position: absolute;
  top: 13px;
  right: 17px;
  font-size: 13px;
  font-family: "Red Hat Display", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  color: #fcfcfd;
  background: #ff4747;
  padding: 5px 15px;
  letter-spacing: 0.5px;
  border-top-left-radius: 0px;
  border-top-right-radius: 17px;
  border-bottom-right-radius: 17px;
  border-bottom-left-radius: 0px;
  -webkit-border-top-left-radius: 17px;
  -webkit-border-top-right-radius: 0px;
  -webkit-border-bottom-right-radius: 17px;
  -webkit-border-bottom-left-radius: 0px;
  -moz-border-radius-topleft: 0px;
  -moz-border-radius-topright: 17px;
  -moz-border-radius-bottomright: 17px;
  -moz-border-radius-bottomleft: 0px;
  box-shadow: 8px 8px 18px 0px rgba(42, 67, 113, 0.3);
  -webkit-box-shadow: 8px 8px 18px 0px rgba(42, 67, 113, 0.3);
  -moz-box-shadow: 8px 8px 18px 0px rgba(42, 67, 113, 0.3);
}
.base-btn1 {
  margin-top: 23px;
  font-size: 17px;
  font-weight: 700;
  padding: 10px 30px;
  display: inline-block;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease-in;
  color: #5a3733;
  background-color: #ffffff;
  border: 0px;
  box-shadow: -4px -4px 10px 0px #fff9, -4px -4px 5px 0px #fff9, 4px 4px 10px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1), inset 0px 0px 0px 0px #fff9, inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1), inset 0px 0px 0px 0px #fff9, inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: -4px -4px 10px 0px #fff9, -4px -4px 5px 0px #fff9, 4px 4px 10px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1), inset 0px 0px 0px 0px #fff9, inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1), inset 0px 0px 0px 0px #fff9, inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -4px -4px 10px 0px #fff9, -4px -4px 5px 0px #fff9, 4px 4px 10px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1), inset 0px 0px 0px 0px #fff9, inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1), inset 0px 0px 0px 0px #fff9, inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
  -o-box-shadow: -4px -4px 10px 0px #fff9, -4px -4px 5px 0px #fff9, 4px 4px 10px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1), inset 0px 0px 0px 0px #fff9, inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1), inset 0px 0px 0px 0px #fff9, inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
  -o-ms-shadow: -4px -4px 10px 0px #fff9, -4px -4px 5px 0px #fff9, 4px 4px 10px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1), inset 0px 0px 0px 0px #fff9, inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1), inset 0px 0px 0px 0px #fff9, inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
  -webkit-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
  -moz-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
  -o-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
  -ms-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
}
.base-btn1:hover {
  color: #ff4747;
  box-shadow: 0px 0px 0px 0px #fff9, 0px 0px 0px 0px #fff9, 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 0px 0px 0px 0px rgba(0, 0, 0, 0.1), inset -4px -4px 10px 0px #fff9, inset -4px -4px 5px 0px #fff9, inset 4px 4px 10px 0px rgba(0, 0, 0, 0.1), inset 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 0px 0px 0px #fff9, 0px 0px 0px 0px #fff9, 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 0px 0px 0px 0px rgba(0, 0, 0, 0.1), inset -4px -4px 10px 0px #fff9, inset -4px -4px 5px 0px #fff9, inset 4px 4px 10px 0px rgba(0, 0, 0, 0.1), inset 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 0px 0px #fff9, 0px 0px 0px 0px #fff9, 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 0px 0px 0px 0px rgba(0, 0, 0, 0.1), inset -4px -4px 10px 0px #fff9, inset -4px -4px 5px 0px #fff9, inset 4px 4px 10px 0px rgba(0, 0, 0, 0.1), inset 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0px 0px 0px 0px #fff9, 0px 0px 0px 0px #fff9, 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 0px 0px 0px 0px rgba(0, 0, 0, 0.1), inset -4px -4px 10px 0px #fff9, inset -4px -4px 5px 0px #fff9, inset 4px 4px 10px 0px rgba(0, 0, 0, 0.1), inset 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0px 0px 0px 0px #fff9, 0px 0px 0px 0px #fff9, 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 0px 0px 0px 0px rgba(0, 0, 0, 0.1), inset -4px -4px 10px 0px #fff9, inset -4px -4px 5px 0px #fff9, inset 4px 4px 10px 0px rgba(0, 0, 0, 0.1), inset 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
  -webkit-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
  -moz-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
  -o-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
  -ms-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
}

/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.topslider5 {
  position: relative;
  background-size: cover;
  padding: 27px 0 67px 0;
  background-position: center;
  width: 100%;
  float: left;
  background-color: #f7f7f7;
}
.topslider5 h1 {
  color: #5A3733;
  text-align: center;
  line-height: 1.2em;
  font-weight: 900;
  font-family: 'Signika', serif;
  font-size: 47px;
  margin-bottom: 37px;
} 
.topslider5 img {
  max-width: 167px;
  height: 243px;
  margin: 0 auto 37px;
  display: block;
  box-shadow: 3px 3px 7px #0000004f;
  border-radius: 5px;
}
.title-table2 {
    text-align: center;
    margin: 0 auto 17px;
    display: block;
    color: #ff4747;
    font-size: 17px;
    height: 27px;
    overflow: hidden;
}
.topslider5 .app:hover .heading.price {
  opacity: 1;
}
.topslider5 .heading.price {
  padding: 13px 13px;
}
.topslider5 .heading.color-white .header {
  font-size: 27px;
}



/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- *//* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.topslider6 {
  position: relative;
  background-image: url('./Img/pattern.jpg');
  padding: 67px 0 67px 0;
  background-position: center;
  width: 100%;
  float: left;
}
.aaa4 {
  position: relative;
  border-radius: 7px;
  padding: 27px 17px 37px 17px;
  background-color: #fff;
  border: 1px solid #f0f2f3;
  margin-bottom: 37px;
  box-shadow: 0 3px 24px rgba(0,0,0,.04);
}
.aaa4:hover{
  box-shadow: 0px 0px 0px 0px #fff9, 0px 0px 0px 0px #fff9, 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 0px 0px 0px 0px rgba(0, 0, 0, 0.1), inset -4px -4px 10px 0px #fff9, inset -4px -4px 5px 0px #fff9, inset 4px 4px 10px 0px rgba(0, 0, 0, 0.1), inset 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0px 0px 0px 0px #fff9, 0px 0px 0px 0px #fff9, 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 0px 0px 0px 0px rgba(0, 0, 0, 0.1), inset -4px -4px 10px 0px #fff9, inset -4px -4px 5px 0px #fff9, inset 4px 4px 10px 0px rgba(0, 0, 0, 0.1), inset 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 0px 0px #fff9, 0px 0px 0px 0px #fff9, 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 0px 0px 0px 0px rgba(0, 0, 0, 0.1), inset -4px -4px 10px 0px #fff9, inset -4px -4px 5px 0px #fff9, inset 4px 4px 10px 0px rgba(0, 0, 0, 0.1), inset 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0px 0px 0px 0px #fff9, 0px 0px 0px 0px #fff9, 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 0px 0px 0px 0px rgba(0, 0, 0, 0.1), inset -4px -4px 10px 0px #fff9, inset -4px -4px 5px 0px #fff9, inset 4px 4px 10px 0px rgba(0, 0, 0, 0.1), inset 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0px 0px 0px 0px #fff9, 0px 0px 0px 0px #fff9, 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 0px 0px 0px 0px rgba(0, 0, 0, 0.1), inset -4px -4px 10px 0px #fff9, inset -4px -4px 5px 0px #fff9, inset 4px 4px 10px 0px rgba(0, 0, 0, 0.1), inset 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
    -webkit-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
    -moz-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
    -o-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
    -ms-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
}
.topslider6 .app:hover .heading.price {

  opacity: 1;
}
/* @media (min-width: 992px) { 
.topslider6 .col-md-3 {
    flex: 0 0 19.666667%;
    max-width: 19.666667%;
}
} */
.topslider6 h1 {
  color: #5A3733;
  text-align: center;
  line-height: 1.2em;
  font-weight: 900;
  font-family: 'Signika', serif;
  font-size: 47px;
  margin-bottom: 37px;
} 
.topslider6 .book {
  margin-bottom: 7px
}

.topslider6 .book-wrapper2 img {
  box-shadow: -1px 1px 10px #807f7f;
  margin: 10px auto 17px;
  display: block;
  max-width: 207px;
  height: 277px;
  transition: .3s;
  border-radius: 10px;
}
.aaa4 .book-wrapper2:hover img{
  transform: scale(1.1) rotate(0deg);
}
.aaa4 .book-shadow {
  width: 97px;
  height: calc(13px + var(--thickness));
}
.aaa4 .arrow-read-more {
  position: absolute;
  right: 17px;
  bottom: 7px;
  transform: translateX(0);
}
.aaa4 .arrow-read-more-2{
  right: 17px;
  bottom: 7px;
}

.aaa4:hover .arrow-read-more-2 {
  transform: translateX(0);
  opacity: 1;
  color: #2b2d42;
}
.aaa4:hover .arrow-read-more {
  opacity: 0;
  transform: translateX(100%);
}
.aaa4 h4 {
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  margin: 0 auto;
  display: block;
  height: 63px;
  font-weight: 600;
  overflow: hidden;
  font-family: "Lato",sans-serif;
  color: #ff4747;
}
.aaa4:hover h4{
  color: #ff4747;
}
/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.Appfooter {
  background-color: #f64442;
  padding-top: 33px;
  padding-bottom: 57px;
  position: relative;
  clear: both;
  z-index: 0;
}
.Appfooter ul.main-menu>li.menu-item>a {
  font-size: 20px;
  color: #ffffff;
}
.Appfooter .col-md-3{
 display: none;
}
.Appfooter .col-md-9{
  width:100%;
  max-width: 100%;
}
.copyright-block {
  position: relative;
  background-color: #F8F7F1;
  color: #5A3733;
  text-align: left;
  padding: 27px 0 22px;
}
@media (max-width: 768px) {
  .copyright-block {
    text-align: center;
  }
  .copyright-block p {
    font-size: 14px;
  }
}
@media (max-width: 479px) {
  .copyright-block p {
    font-size: 14px;
  }
}
.copyright-block div {
  font-size: 12px;
}
.copyright-block p {
  margin-bottom: 0;
  display: inline-block;
}
.menu-itemcopyright {
  font-size: 17px;
  position: absolute;
  right: 27px;
  top: 47px;
}
.menu-itemcopyright a{
  color: #020202!important;
}
.copyright-block a {
  color: #ff4747;
}
.copyright-block a:hover {
  color: #0078de;
}
.copyright-block .container {
  position: relative;
}
.copyright-block.copyright-layout-copyright {
  background-color: #5A3733;
  color: #fff;
  text-align: center;
}
.copyright-block.copyright-layout-simple {
  text-align: center;
  padding: 57px 0 27px;
  background-color: transparent;
  background-image: url('./Img/footer-white-waves.png');
  background-repeat: repeat-x;
  position: relative;
  top: -14px;
}
.copyright-block.copyright-layout-simple img{
  float: left;
  max-width: 300px;
  margin-top: 17px;
}
.copyright-block.copyright-layout-simple .logo-footer {
    text-align: left;
}
.copyright-block.copyright-layout-simple .logo-footer p {
  margin-top: 17px;
}
@media (max-width: 991px) {
  .copyright-block.copyright-layout-simple .logo-footer {
    text-align: center;
    margin: 0 auto;
    display: block;
  }
  .copyright-block.copyright-layout-simple .logo-footer p {
    margin-bottom: 17px;
}
  .copyright-block.copyright-layout-simple img {
    float: none;
}
}
.copyright-block.copyright-layout-simple .go-top {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.copyright-block.copyright-layout-copyright_waves {
  text-align: center;
  background-color: transparent;
  background-image: url('./Img/footer-white-waves.png');
  background-repeat: repeat-x;
  position: relative;
  margin-top: -100px;
  display: inline-block;
  width: 100%;
  padding-top: 14px;
}
.copyright-block.copyright-layout-copyright_waves .container {
  width: 100% !important;
  background-color: #fff;
  background-color: var(--white);
  padding: 47px 0 22px;
}
.copyright-block.copyright-layout-copyright_waves .go-top {
  display: none;
}
.copyright-block .ltx-social .header {
  display: none;
}
.copyright-block .ltx-social ul {
  list-style: none;
  padding: 0;
  margin-bottom: 35px;
}
.copyright-block .ltx-social ul li {
  display: inline-block;
}
.copyright-block .ltx-social ul li a {
  line-height: 52px;
  width: 50px;
  height: 50px;
  background-color: #FFD832;
  background-color: var(--second);
  color: #EB6D2F;
  color: var(--main);
  text-align: center;
  display: block;
  font-size: 18px;
  margin: 0 10px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-border-radius: 50%;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 50%;
  -moz-background-clip: padding;
  border-radius: 50%;
  background-clip: padding-box;
}
.copyright-block .ltx-social ul li a:hover {
  background-color: #5A3733;
  background-color: var(--black);
  color: #fff;
  color: var(--white);
}
.copyright-block .go-top {
  position: absolute;
  right: 0px;
  top: -12px;
  color: #5A3733;
  color: var(--black);
  text-align: center;
  font-size: 18px;
  display: block;
  width: 46px;
  height: 46px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-family: 'Signika', serif;
  font-family: var(--font-headers), serif;
  font-weight: 900;
  text-transform: uppercase;
}
.copyright-block .go-top.go-top-img {
  padding: 0;
}
@media (max-width: 768px) {
  .copyright-block .go-top {
    display: none;
  }
}
.copyright-block .go-top .fa {
  display: block;
  font-size: 18px;
  font-weight: bold;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  top: 50%;
  left: 50%;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin-top: -4px;
  z-index: 11;
  color: #fff;
  color: var(--white);
}
.copyright-block .go-top .fa:before {
  content: "\f106";
}
.copyright-block .go-top .ltx-hex {
  top: 0px;
  left: 0;
  position: absolute;
  z-index: 10;
  border-radius: 4px;
  width: 46px;
  height: 39.836px;
}
.copyright-block .go-top .ltx-hex .ltx-hex-inner:before {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.copyright-block .go-top:hover {
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -o-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}
.copyright-block .go-top:hover .ltx-hex-inner:before {
  background-color: #5A3733;
  background-color: var(--black);
}
/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.scrolls {
  background-color: #ff4747;
  color: white;
  width: 37px;
  height: 37px;
  line-height: 30px;
  border-radius: 7px;
  font-size: 20px;
  position: fixed;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
}
.scrolls:hover {
  background-color: #ff4747;
}
.scrolls .svg-inline--fa.fa-w-14 {
  width: 16px;
  margin: 8px auto;
  display: block;
  vertical-align: middle;
  line-height: 37px;
}
/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
.breadcrumbs {
  background-repeat: repeat;
  background-position: top center;
  background-size: contain;
  position: relative;
}
.breadcrumbs .content-box h1 {
  position: relative;
  color: #ff4747;
  line-height: 1.2em;
  font-weight: 900;
  font-family: 'Signika', serif;
  margin-bottom: 17px;
  font-size: 37px;
}
.breadcrumbs .content-box .bread-crumb li {
  position: relative;
  display: inline-block;
  font-size: 15px;
  color: #000000;
  padding-right: 22px;
  margin-right: 6px;
}
.breadcrumbs .content-box .bread-crumb li:before {
  position: absolute;
  content: '';
  width: 7px;
  height: 7px;
  border-radius: 50%;
  top: 9px;
  right: 4px;
  background: #ff4747;
}
.breadcrumbs .content-box .bread-crumb li a {
  color: black;
}
.breadcrumbs ul{
  margin: 0 auto;
  display: table;
}
.breadcrumbs .content-box .bread-crumb li:last-child {
  padding: 0px;
  margin: 0px;
}
.breadcrumbs .content-box .bread-crumb li:last-child:before {
  display: none;
}
/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
.login-1 {
  padding: 67px 0 67px 0;
  background-repeat: repeat;
  background-position: center;
  background-size: cover;
  z-index: -7;
  background-color: #f8f7f2;
 
  background-image: linear-gradient(rgb(255 255 255), rgb(255 255 255 / 49%)), url('./Img/34.jpg');
}
.login-forms h1 {
  float: left;
  display: block;
  width: 100%;
  text-align: left;
  margin-bottom: 17px;
  font-size: 36px;
  line-height: 47px;
  color: #ff4747;
  font-weight: 700;
}
.login-forms p {
  text-align: left;
  float: left;
  display: block;
  width: 100%;
  margin-bottom: 17px;
}
.login-1 h6 {
  cursor: pointer;
  clear: both;
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 17px;
  text-align: center;
  color: #403c3c;
  font-family: 'Lato', sans-serif;
}
.login-1 h6:hover {
  color: rgb(255, 110, 7);
}
.logsss3 {
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  background-image: url('./Img/bg-choose-bike.jpg');
  background-repeat: no-repeat;
  background-clip: border-box;
  -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.07);
  border: none;
  border-radius: 3px;
  padding: 60px 60px 60px 60px;
  height: fit-content;
  background-size: cover;
}
.login-1 .form-group label {
  font-size: 16px;
}
.login-1 .form-group {
  margin-bottom: 27px;
}
.thm-btn {
  background-color: #ff4747;
  background-image: -webkit-linear-gradient(45deg, #f32727 15%, #f75f5f 65%);
  padding: 7px 22px 10px 27px;
  font-size: 20px;
  letter-spacing: 0.7px;
  color: white;
  border-radius: 7px;
}
.thm-btn:hover {
  background-image: -webkit-linear-gradient(45deg, #f87070 15%, #ff4747 65%);
}
.login-1 .svg-inline--fa.fa-w-16 {
  width: 20px;
  height: 17px;
}
.form-control {
  height: calc(2.5em + .75rem + 7px);
}
/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.paymentpendings{
  padding: 67px 0 67px 0;
  background-repeat: repeat;
  background-position: top center;
  background-size: cover;
  z-index: -7;
  background-image: linear-gradient(rgb(255 255 255 / 76%), rgb(255 255 255 / 76%)), url(./Img/sss3.png);
}
.paymentpendings .breadcrumbs .content-box h1 {
  margin-bottom: 57px;
  font-size: 37px;
  text-align: center;
}
.basccsss {
  padding: 0;
  height: 237px;
  overflow: hidden;
  border-bottom: 1px solid #e6e6fa;
  margin-bottom: 47px;
}
.basccsss img {
  height: 187px;
  width: auto;
  overflow: hidden;
  border-radius: .5vmin;
  box-shadow: -1px 1px 10px #807f7f;
  margin: 0 auto 17px;
  display: block;
  max-width: 100%;
}
.basccsss h3 {
  color: #000000;
  font-size: 25px;
  line-height: 27px;
  font-family: 'Signika', serif;
  margin-bottom: 10px;
  height: 77px;
  overflow: hidden;
}
.btn-primary5 {
  color: #fff;
  background-color: #ff4747;
  border-color: #ff4747;
  margin-top: 27px;
  margin-left: 0;
  font-size: 20px;
  padding: 10px 17px 10px 17px;
  box-shadow: 0px 7px 10px #a09e9e;
  border-radius: 10px;
  font-weight: 700;
}

/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
.members{
  padding: 67px 0 67px 0;
  background-repeat: repeat;
  background-position: top center;
  z-index: -7;
  background-image: linear-gradient(rgb(255 255 255 / 86%), rgb(255 255 255 / 86%)), url(./Img/sss3.png);
}
.memberstabsss {
  margin: 0 auto;
  display: table;
}
.memberstabsss li:hover {
  color: #ffffff!important;
  background-color: #ff4747;
}
.memberstabsss li {
  cursor: pointer;
  position: relative;
  z-index: 1;
  display: inline-block;
  padding: 17px;
  font-size: 17px;
  text-transform: capitalize;
  font-weight: 500;
  letter-spacing: 1px;
  box-shadow: 0 0.5rem 1rem 0 rgba(44, 51, 73, 0.1);
  border: 1px solid #fbf8f8;
  margin: 0 7px 0 7px;
  border-radius: 4px;
}
.memberstabsss li svg{
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25px;
  flex: 0 0 25px;
  max-width: 25px;
  width: 25px;
  font-size: 18px;
  font-weight: 500;
  margin-right: 10px;
}
.memberstabsss .active {
  color: #ffffff!important;
  background-color: #ff4747;
}
.card {
  display: -ms-flexbox;
  display: flow-root;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff75;
  background-clip: border-box;
  border-radius: 3px;
  min-height: 100vh;
  border: 1px solid #dcdce4;
  box-shadow: 1px 3px 7px #ded8d8;
  padding: 27px;
}
.btn-primary4 {
  color: #fff;
  background-color: #676767;
  border-color: #656565;
  margin-top: 0px;
  margin-left: 17px;
  font-size: 17px;
  padding: 10px 17px 10px 17px;
  box-shadow: 0px 7px 10px #a09e9e;
  border-radius: 10px;
}
.iconsss2sss {
  width: 17px;
  color: black;
  margin-right: 7px;
  vertical-align: middle;
}
.myaccount-content span{
  float: right;
  font-size: 23px;
  color: #ff4747;
}
.members .breadcrumbs h1{
  text-align: center;
}
/* ------------------------------------------------------------------------- */
.aaa6{
  margin-top: 37px;
}
/* ------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.profileimgsss {
  width: 167px;
  max-width: 167px;
  max-height: 167px;
  min-width: 167px;
  min-height: 167px;
  overflow: hidden;
  object-fit: cover;
  object-position: top;
  height: 167px;
  border-radius: 10%;
  box-shadow: 1px 7px 7px 0px #cac6c6;
}
.profilepageleftsss h2 {
  position: relative;
  color: #000000;
  line-height: 1.2em;
  margin-top: 27px;
  font-size: 37px;
}
.central-meta2 .form-control {
  height: auto;
  font-size: 17px;
  line-height: 27px;
  color: #fd6500;
  background: #edf2f6 none repeat scroll 0 0;
  border: 1px solid #e4e4e4;
}
.central-meta2 .btn-primary {
  background-color: #ff4747;
  border: 1px solid #ff4747;
  color: #fff;
  margin: 0 auto;
  display: block;
  padding: 7px 27px 7px 27px;
  font-size: 20px;
}
.myaccount-content {
  border-bottom: 1px dashed #cecece;
  padding-bottom: 10px;
  line-height: 1.2em;
  font-weight: 900;
  margin-bottom: 47px;
  font-size: 30px;
}
.zzz2 {
  position: relative;
}
.zzz {
  position: absolute;
  right: 10%;
  top: 23%;
  text-align: center;
  display: block;
  margin: 0 auto;
  background-color: #ff4747;
  border: 1px solid #ff4747;
}
.form-group2{
  display: block;
  margin: 37px auto;
}
.acqcss2 .menu-item {
  list-style: none;
  background-color: #ff4747;
  padding: 7px 17px 7px 17px;
  margin-bottom: 27px;
  display: inline-block;
  margin-right: 17px;
}
/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.timeline {
  position: relative;
  width: 660px;
  margin: 0 auto;
  margin-top: 20px;
  padding: 1em 0;
  list-style-type: none;
}

.timeline:before {
  position: absolute;
  left: 50%;
  top: 0;
  content: ' ';
  display: block;
  width: 6px;
  height: 100%;
  margin-left: -3px;
  background: rgb(80,80,80);
  background: -moz-linear-gradient(top, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(30,87,153,1)), color-stop(100%,rgba(125,185,232,1)));
  background: -webkit-linear-gradient(top, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
  background: -o-linear-gradient(top, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
  background: -ms-linear-gradient(top, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
  background: linear-gradient(to bottom, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
  
  z-index: 5;
}

.timeline li {
  padding: 1.7em 0;
}

.timeline li:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.direction-l {
  position: relative;
  width: 300px;
  float: left;
  text-align: right;
}

.direction-r {
  position: relative;
  width: 300px;
  float: right;
}

.flag-wrapper {
  position: relative;
  display: inline-block;
  
  text-align: center;
}

.flag {
  position: relative;
  display: inline;
  background: rgb(255 71 71);
  border-radius: 5px;
  font-weight: 600;
  text-align: left;
  padding: 10px 27px 12px 27px;
}
.members .flag:hover {
  background: #2196F3;
}
.members .direction-l .flag:hover:before {
  border: 4px solid #2196F3!important;
}
.members .direction-r .flag:hover:before {
  border: 4px solid #2196F3!important;
}
.members .direction-l .flag:hover:after {
  border-left-color: #2196F3!important;
}
.members .direction-r .flag:hover:after {
  border-right-color: #2196F3!important;
}
.flag a{
  color:white;
}
.ppp{
  border-left: 1px dashed #cecece;
}
.direction-l .flag {
  -webkit-box-shadow: -1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
  -moz-box-shadow: -1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
  box-shadow: -1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
}

.direction-r .flag {
  -webkit-box-shadow: 1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
  -moz-box-shadow: 1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
  box-shadow: 1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
}

.direction-l .flag:before,
.direction-r .flag:before {
  position: absolute;
  top: 50%;
  right: -40px;
  content: ' ';
  display: block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  background: #fff;
  border-radius: 10px;
  border: 4px solid rgb(255,80,80);
  z-index: 10;
}

.direction-r .flag:before {
  left: -40px;
}

.direction-l .flag:after {
  content: "";
  position: absolute;
  left: 100%;
  top: 50%;
  height: 0;
  width: 0;
  margin-top: -8px;
  border: solid transparent;
  border-left-color: rgb(255 71 71);
  border-width: 8px;
  pointer-events: none;
}

.direction-r .flag:after {
  content: "";
  position: absolute;
  right: 100%;
  top: 50%;
  height: 0;
  width: 0;
  margin-top: -8px;
  border: solid transparent;
  border-right-color: rgb(255 71 71);
  border-width: 8px;
  pointer-events: none;
}

.time-wrapper {
  display: inline;
  
  line-height: 1em;
  font-size: 0.66666em;
  color: rgb(250,80,80);
  vertical-align: middle;
}

.direction-l .time-wrapper {
  float: left;
}

.direction-r .time-wrapper {
  float: right;
}

.time {
  display: inline-block;
  padding: 4px 6px;
  background: rgb(248,248,248);
}

.desc {
  margin: 1em 0.75em 0 0;
  
  font-size: 0.77777em;
  font-style: italic;
  line-height: 1.5em;
}

.direction-r .desc {
  margin: 1em 0 0 0.75em;
}



/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.radioqqq {
  display: block;
  float: left;
  background-color: rgb(255, 255, 255);
  padding: 17px 7px 17px 7px;
  margin: 10px 0px 10px 0px;
  position: relative;
  width: 100%;
}
.radioqqq label {
  color: white;
  font-size: 17px;
}
.radioqqq h2 {
  margin: 0 auto 7px;
  display: inline-block;
  text-align: center;
  color: #5a3733;
  font-size: 31px;
  line-height: 37px;
  font-weight: 600;
  vertical-align: text-bottom;
  letter-spacing: 0.7px;
}
.price {
  display: inline-block;
  text-align: center;
  font-family: 'Signika', serif;
}
.radioqqq .radio {
  display: inline;
  opacity: 0;
  margin: 0;
  overflow: hidden;
  -webkit-appearance: none;
}
.radioqqq .radio__label {
  display: inline-block;
  height: 50px;
  position: relative;
  padding: 10px 13px 15px 48px;
  text-align: right;
  cursor: pointer;
  margin: 0 auto;
  font-size: 20px;
  color: black;
  background-color: #f8f7f2;
  box-shadow: 1px 3px 7px #bdbcbc;
}
.radioqqq .radio__label:before, .radioqqq .radio__label:after {
  position: absolute;
  content: "";
  -webkit-transition: border-color 200ms ease, -webkit-transform 200ms ease;
  transition: border-color 200ms ease, -webkit-transform 200ms ease;
  transition: transform 200ms ease, border-color 200ms ease;
  transition: transform 200ms ease, border-color 200ms ease, -webkit-transform 200ms ease;
  box-sizing: border-box;
  border-radius: 100%;
}
.radioqqq .radio__label:hover:before {
  border-color: #fb872b;
}
.radioqqq .radio__label:before {
  left: 10px;
  top: 10px;
  width: 30px;
  height: 30px;
  border: 2px solid #dbdbdb;
}
.radioqqq .radio__label:after {
  top: 15px;
  left: 15px;
  width: 20px;
  height: 20px;
  transform: scale(0);
  background-color: #ffffff;
}
.radioqqq .radio__label:hover {
  color: white;
  background-color: #4caf50;
}
.radioqqq .radio__label:hover:before {
  border-color: #fb872b;
}
.radioqqq .radio:checked + .radio__label {
  color: rgba(255, 255, 255, 0.87);
  background-color: #4CAF50;
}
.radioqqq .radio:checked + .radio__label:before {
  border-color: #ffffff;
}
.radioqqq .radio:checked + .radio__label:after {
  transform: scale(1);
}
.listing-duration {
  font-size: 20px;
  margin-bottom: 10px;
  display: inline-block;
  text-align: left;
  padding: 0 7px 0 7px;
  color: #000000;
  letter-spacing: 0.7px;
  line-height: 37px;
  white-space: break-spaces;
}
.ppp2 p{
    color: #ff4747;
    font-size: 23px;
    border: 1px dashed #ff4747;
    padding: 17px;
    line-height: 47px;
}
.currency {
  display: inline-block;
  margin-top: 0px;
  vertical-align: top;
  font-size: 33px;
  font-weight: 300;
  color: #ffffff;
}
.value {
  font-size: 33px;
  font-weight: 900;
  margin-left: 0px;
  font-family: 'Signika', serif;
  color: #ffffff;
}
.duration {
  font-size: 1.4rem;
  color: #fd6500;
  display: none;
}
.duration::before {
  content: '/';
  margin-right: 2px;
}
/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.addbook{
  padding: 67px 0 67px 0;
  background-image: linear-gradient(rgb(255 255 255 / 76%), rgb(255 255 255 / 76%)), url(./Img/sss3left.png);
}
.addbook h3{
    color: #5A3733;
    text-align: left;
    line-height: 1.2em;
    font-weight: 900;
    font-family: 'Signika', serif;
    margin-bottom: 67px;
    font-size: 37px;
}
.addbook .timeline:before {
  left: 0;
}
.addbook .timeline {
  width: auto;
  padding: 3em 0;
  margin: 67px 0 67px 0;
}
.addbook .direction-r {
  width: auto;
  float: none;
  left: 30px;
}
.addbook .flag {
  color: white;
  font-size: 27px;
}
.addbook .direction-lsss {
  position: absolute;
  top: -3%;
  left: -23%;
}
.addbook .timeline li {
  padding: 1em 0;
  position: relative;
}
.addbook .thm-btn.lsss {
  position: absolute;
  top: -67px;
  left: -83px;
  background-color: #2196F3;
  background-image: -webkit-linear-gradient(45deg, #0083ea 15%, #03A9F4 65%);
  padding: 10px 22px 13px 27px;
  font-size: 23px;
}
.addbook .thm-btn.rsss {
  position: absolute;
  bottom: -67px;
  left: -83px;
  background-color: #2196F3;
  background-image: -webkit-linear-gradient(45deg, #0083ea 15%, #03A9F4 65%);
  padding: 10px 22px 13px 27px;
  font-size: 23px;
}
.addbook .thm-btn.lsss:hover {
  background-color: #FF9800;
  background-image: -webkit-linear-gradient(45deg, #ef930a 15%, #f7b900 65%);
}
.addbook .thm-btn.rsss:hover {
  background-color: #FF9800;
  background-image: -webkit-linear-gradient(45deg, #ef930a 15%, #f7b900 65%);
}
.bookads2{
  position: relative;
  margin: 0 auto;
  padding: 67px 0 67px 0;
  background-image: linear-gradient(rgb(255 255 255 / 76%), rgb(255 255 255 / 87%)), url(./Img/sss3.png);
}
.public-DraftEditor-content {
  min-height: 300px;
  max-height: 300px;
  padding: 10px 27px 10px 27px;
  white-space: nowrap!important;
}
.rdw-editor-main {
  background-color: #edf2f6;
  border: 1px solid #edf2f6;
  margin-bottom: 17px;
}
.bookads2 .adverts-form-required {
  font-weight: 700;
  color: #e9322d;
  font-size: 27px;
}
label {
  display: inline-block;
  margin-bottom: 10px;
  font-size: 17px;
  letter-spacing: 1px;
  margin-top: 10px;
}
.bookads2 .previewimg {
  max-width: 167px;
  margin-bottom: 17px;
}
.removeqqq {
  margin: 0 0 0 27px;
  background: linear-gradient(180deg,#ff4747,#fb6767 0,#ff2020 99%);
  color: #fff;
  outline: none;
  padding: 7px 17px;
  font-size: 16px;
  border-radius: 7px;
}
.btn-primary {
  color: #fff;
  background-color: #f64442;
  border-color: #f64442;
  float: left;
  margin-top: 27px;
  font-size: 26px;
  padding: 10px 27px 10px 27px;
  box-shadow: 0px 7px 10px #a09e9e;
  
  border-radius: 57px;
}
.btn-primary .svg-inline--fa {
  height: 23px;
}
/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.zzz7 {
  margin-top: 37px;
  margin-left: 37px;
}
.zzz7 h3 {
  font-size: 18px;
  font-family: 'Lato', sans-serif;
  color: #444040;
}
.zzz7 .svg-inline--fa {
  width: 20px;
  color: #ff4747;
  margin-right: 7px;
}
.zzz7 p {
    position: relative;
    font-size: 17px;
    color: #008eff;
    display: inline-block;
    cursor: pointer;
    word-break: break-all;
}
.zzz8 img{
  max-width: 207px;
  margin-bottom:37px;
  box-shadow: 0px 1px 11px #928f8f;
  margin: 37px auto;
  display: block;
  background-color: #f8f7f2;
}
.redmoesss {
  height: 300px;
  overflow: hidden;
  margin-top: 37px;
}
.redmoesssqqq{
  height: auto!important;
}
.redmoesss span{
  font-family: 'Lato', sans-serif!important;
  font-size: 17px!important;
  line-height: 37px;
  letter-spacing: 0.7px;
}
.select-icon{
  vertical-align: top;
  padding: 0;
  width: 24px;
  height: 24px;
  display: table-cell;
  text-align: left;
}
.bookadspreview input[id^=radio] {
  display: none;
}
.bookadspreview input[id^=radio]:checked+label {
  height: 26px;
  width: 26px;
  display: inline-block;
  padding: 0;
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid #000000;
  background-color: #ff0000;
  margin-right: 13px;
}
.bookadspreview input[id^=radio]+label {
  height: 26px;
  width: 26px;
  display: inline-block;
  padding: 0;
  border-radius: 50%;
  font-weight: 700;
  font-size: 14px;
  float: left;
  color: #ccc;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  background: #fff;
  margin-bottom: 2px;
  background-color: #fff;
  border: 1px solid #656565;
  margin-right: 13px;
}
.bookadspreview{
  padding: 47px 0 67px 0;
  background-image: linear-gradient(rgb(255 255 255 / 90%), rgb(255 255 255 / 90%)), url(./Img/sss3.png);

}
.redmoesssmmm{
  color: #ff4747;
    font-size: 17px;
    padding-bottom: 17px;
    cursor: pointer;
}
.bookadspreview h3 {
  margin-bottom: 17px;
  font-size: 20px;
  margin-top: 27px;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
}
.bookadspreview .zzz7 h3 span{
  color: #ff4747;
}
.bookadspreviewlsss h1 {
  color: #ff4747;
  text-align: left;
  line-height: 1.2em;
  font-weight: 900;
  font-family: 'Signika', serif;
  margin-bottom: 17px;
  font-size: 27px;
}
.bookadspreviewlsss .stripsss{
  color: #6873e5;
  font-size: 26px;
  font-weight: 700;
  font-family: 'Signika', serif;
}
.bookadspreviewlsss .thm-btn {
  background-image: -webkit-linear-gradient(45deg, #1477c5 15%, #51a7ec 65%);
  padding: 13px 22px 13px 27px;
  font-size: 23px;
  margin: 37px auto;
  display: table;
  font-weight: 700;
  cursor: pointer;
}
.bookadspreviewlsss .thm-btn:hover {
  background-image: -webkit-linear-gradient(45deg, #f87070 15%, #ff4747 65%);
  color: white;
}
.masssqqq {
  margin-top: 47px;
}
.yyy{
  border-right: 1px dashed #d8d6d6;
  padding-right: 57px;
}
.yyy h1 {
  font-size: 24px;
  margin-top: 0;
  font-family: 'Lato', sans-serif;
  letter-spacing: 0.7px;
  line-height: 37px;
  text-transform: capitalize;
  font-weight: 600;
}
.zzz8{
  background-color: #f8f7f2;
  margin-top: 17px;
}
.sqqssqqq{
  float: right;
}
.Pay{
  font-size: 26px;
  font-weight: 700;
  font-family: 'Signika', serif;
  color: #173c91;
}
.pal{
  font-size: 26px;
  font-weight: 700;
  font-family: 'Signika', serif;
  color: #16a1e0;
}
/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.pymt-type-name {
  font-weight: 500;
  font-size: 20px;
  padding-bottom: 0px;
  color: #3c444c;
  margin-bottom: 7px!important;
}
.pymt-type-desc {
  font-size: 16px;
  color: #333232;
  margin: 10px 0 10px 0;
}
.payment-row {
  background-color: #f8f7f2;
  width: 100%;
  margin: 0px 0px 0px 0px;
  padding: 22px 30px 10px 30px;
  text-align: left;
  display: table;
  position: relative;
  box-shadow: 2px 3px 7px #d2d2d2;
}
.aaa5 {
  width: 100%;
  clear: both;
  float: left;
  position: relative;
  margin-top: 27px;
  border: 1px solid #f3a39f;
  padding: 37px;
  border-radius: 7px;
}
.aaa5 h4 {
  float: left;
  display: block;
  width: 100%;
  text-align: left;
  margin-bottom: 37px;
  font-size: 27px;
  line-height: 47px;
  color: #0679e8;
  font-weight: 500;
  letter-spacing: 1px;
  text-shadow: 2px 1px 1px #cdcdce;
}
.payment-titleqqq{
  color: #ff7207;
  float: left;
  display: block;
  width: 100%;
  text-align: left;
  font-size: 23px;
  line-height: 47px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: capitalize;
}
/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.bookads2 .central-meta {
  background: #ffffff8c none repeat scroll 0 0;
  border-radius: 5px;
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
  padding: 67px;
  position: relative;
  box-shadow: 3px 3px 10px #e0e0e0;
}
.bookadspreview .bookads2 {
  padding: 0;
  background-image: none;
}
.bookads2 .adverts-form-required2 {
  font-weight: 400;
  color: #e9322d;
  font-size: 17px;
  letter-spacing: 0.7px;
  margin-bottom: 27px;
  float: left;
  display: block;
  width: 100%;
}
.yyy2{
  color: #5A3733;
  text-align: center;
  line-height: 1.2em;
  font-weight: 900;
  font-family: 'Signika', serif;
  margin-bottom: 17px;
  font-size: 47px;
}
/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.btn-primary2 {
  background-image: -webkit-linear-gradient(45deg, #1477c5 15%, #51a7ec 65%);
  padding: 10px 22px 10px 27px;
  font-size: 23px;
  margin: 30px auto 0;
  font-weight: 700;
  cursor: pointer;
  color: white;
  border: 0;
  border-radius: 10px;
}
.xxx{
  color: #5A3733;
  text-align: left;
  line-height: 1.2em;
  font-weight: 900;
  font-family: 'Signika', serif;
  margin-bottom: 67px;
  font-size: 37px;
}
.bookadspreview .addbook {
  padding: 0;
  background-image: none;
}
.bookadspreview iframe{
    width: 600px;
    height: 400px;
    margin: 67px auto;
    display: block;
    box-shadow: 0 8px 16px -6px, 0 0 16px -6px;
    color: #000;
    border-radius: 10px;
}
/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.disablsss, .disablsss input {
  cursor: not-allowed;
}
.disablsss {
  -webkit-filter: blur(1px);
  opacity: .2;
}
/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

.successs{
  padding: 67px 0 67px 0;
  background-repeat: repeat;
  background-position: top center;
  background-size: cover;
  z-index: -7;
  background-image: linear-gradient(rgb(255 255 255 / 76%), rgb(255 255 255 / 76%)), url(./Img/sss3.png);
}
.successs .breadcrumbs h1 {
  text-align: center;
}
.ooo2{
  background-color: white;
  padding: 67px;
  box-shadow: 1px 3px 10px #c5c5c5;
}
.ooo2 h2{
    position: relative;
    color: #4CAF50;
    line-height: 1.2em;
    font-weight: 900;
    font-family: 'Signika', serif;
    margin-bottom: 27px;
    font-size: 27px;
}
.ooo2 h3{
  margin-bottom: 17px;
  font-size: 20px;
  margin-top: 27px;
  font-family: "Lato",sans-serif;
  font-weight: 700;
  line-height: 37px;
}
.ooo2 .thm-btn {
  background-color: #ff4747;
  background-image: -webkit-linear-gradient(45deg, #f32727 15%, #f75f5f 65%);
  padding: 10px 27px 13px 27px;
  font-size: 20px;
  letter-spacing: 0.7px;
  color: white;
  border-radius: 7px;
  margin-top: 17px;
  float: left;
}
.iii2{
    position: absolute;
    top: -67px;
    left: -57px;
    padding: 10px 22px 13px 27px;
}
.iii3 {
  position: absolute;
  bottom: -67px;
  left: -33px;
}
.error{
  color: #ff4747;
}
.iii4 {
  margin-bottom: 27px;
  position: relative;
  display: inline;
  background: rgb(255 71 71);
  border-radius: 5px;
  font-weight: 600;
  text-align: left;
  padding: 17px 27px 17px 27px;
  font-size: 20px;
  float: left;
  background-color: #ff4747;
  background-image: -webkit-linear-gradient(45deg,#f32727 15%,#f75f5f 65%);
  color: white;
  white-space: nowrap;
}
.iii4 a{
  color: white;
}
.iii5 {
  margin-bottom: 27px;
  position: relative;
  display: inline;
  background: rgb(255 71 71);
  border-radius: 5px;
  font-weight: 600;
  text-align: left;
  padding: 13px 27px 13px 27px;
  font-size: 20px;
  float: left;
  margin-right: 27px;
  background-color: #ff4747;
  color: white;
  background-image: -webkit-linear-gradient(45deg,#f32727 15%,#f75f5f 65%);
}
.iii5:hover {
  color: rgb(0, 0, 0);
}
.iii5 a{
  color: white;
}
.profileimgsssbooks {
  width: 167px;
  max-width: 167px;
  max-height: 167px;
  min-width: 167px;
  min-height: 167px;
  overflow: hidden;
  object-fit: cover;
  object-position: top;
  height: 167px;
  border-radius: 10%;
  box-shadow: 1px 7px 7px 0px #cac6c6;
  margin: 0 auto;
  display: block;
}
.uuu h2{
  color: #ff4747;
  text-align: center;
  line-height: 1.2em;
  font-weight: 900;
  font-family: "Signika",serif;
  margin-bottom: 17px;
  font-size: 30px;
  margin-top: 27px;
}
.zzz8s {
  background-color: #ffffffc9;
  margin-top: 17px;
}
.bookadspreview .zzz8s h3 {
  font-size: 18px!important;
  font-weight: 400;
}
.zzz8s .zzz7 .svg-inline--fa {
  width: 17px;
  color: #ff4747;
  margin-right: 7px;
}
.zzz8s .zzz7{
  cursor: pointer;
}
/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.pagebooks{
  padding: 67px 0 67px 0;
  background-repeat: repeat;
  background-position: top center;
  z-index: -7;
  background-image: linear-gradient(rgb(255 255 255 / 93%), rgb(255 255 255 / 93%)), url(./Img/sss3.png);
}
.inner-box .app{
  margin-bottom: 37px;
}
.pagebooks2 h2 {
  position: relative;
  color: #ff4747;
  line-height: 1.2em;
  font-weight: 900;
  font-family: "Signika",serif;
  margin-bottom: 47px;
  font-size: 47px;
  text-align: center;
}
.lower-content {
  margin-bottom: 10px;
  height: 87px;
  overflow: hidden;
}
.lower-content h3 {
  position: relative;
  color: #5a3733;
  line-height: 27px;
  font-weight: 400;
  font-size: 17px;
  font-family: 'Lato', sans-serif;
  margin-top: 10px;
  text-align: center;
}
.lower-content h6{
  font-family: 'Lato', sans-serif;
  color: #ff4747;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
}
.news-block-one {
  width: 100%;
  margin-bottom: 47px;
  margin-top: 37px;
  float: left;
}
.news-block-one .base-btn1 {
  margin-top: 0px;
  font-size: 17px;
  font-weight: 700;
  padding: 10px 30px;
  display: table;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 auto;
}
.imsss{
  width:100%;
}
.page-item.active .page-link{
  background-color: #f64442;
  border-color: #f64442;
}
.page-link {
  color: #f64442;
}
.yyy iframe {
  width: 517px;
  height: 317px;
  margin: 17px auto;
  display: block;
  box-shadow: 0 8px 16px -6px, 0 0 16px -6px;
  color: #000;
  border-radius: 10px;
}
.basccsss2 {
  padding: 0;
  height: 577px;
  overflow: hidden;
  border-bottom: 1px solid #e6e6fa;
  margin-bottom: 47px;
}
/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.standsss{
  background: #f7f7f7;
  border-width: 0 1px 1px 0;
  border-style: solid;
  transition: all .4s ease;
  border-color: rgb(158,158,158,.2);
  border-top: 1px solid #e5e5e5;
}
.standsss2 {
  padding: 47px 17px 47px 17px;
}
.standsss2 h6{
  font-size: 17px;
}
.articles:hover{
  background-color: white;
}
.post-thumb {
  position: relative;
  max-width: 100%;
  transition: all .5s ease;
  padding-left: 50px;
  padding-right: 20px;
}
.post-thumb:before {
  content: ' ';
  position: absolute;
  width: 1px;
  height: 50px;
  left: 8px;
  top: -40px;
  background: rgba(0,0,0,.15);
}
.articles .date-published {
  opacity: .5;
}
.date-published {
  transform: translateY(0) rotate(270deg);
  position: absolute;
  line-height: 1px;
  transform-origin: center right;
  top: 0;
  right: calc(100% - .5em);
  margin-top: 10px;
  text-transform: uppercase;
  white-space: nowrap;
}
.date-published span.big {
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
  display: block;
  text-transform: uppercase;
}
.articles .post-thumb a {
  border-radius: 0 40px 0 20px;
}
.post-thumb a:not(.category-link) {
  background: 0 0;
}
.post-thumb a {
  display: block;
  border-radius: 8px;
}
.post-thumb img{
  border-radius: 0 40px 0 20px;
}
.post-title {
  max-width: 60%;
  font-size: 24px;
  line-height: 1.28em;
  margin-top: 0;
  margin-bottom: 10px;
  letter-spacing: -.5px;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  position: relative;
  margin-top: 10px;
  color: #2b2d42;
  height: 120px;
  overflow: hidden;
}
.post-title a {
  display: inline;
  padding-bottom: 5px;
  background-repeat: no-repeat;
  background-position: left 80%;
  transition: all .3s ease;
  color: #5a3733;
  text-align: center;
  line-height: 1.2em;
  font-weight: 900;
  font-family: "Signika",serif;
  margin-bottom: 17px;
  font-size: 23px;
}
.articles:hover .post-title a {
  background-size: 100% 1px;
  color: #f64442;
  background-image: linear-gradient(90deg, #f64442 0%, #e00905 100%);
}
.articles:hover::after {
  opacity: 1;
  width: calc(100% + 1px);
}

.articles:after {
  position: absolute;
  content: ' ';
  width: 0;
  height: 5px;
  left: 0;
  top: -1px;
  opacity: 0;
  transition: all .4s ease;
  background-image: linear-gradient(90deg, #f64442 0%, #ff100c 100%);
}
.articles {
  border-width: 0 1px 1px 0;
  border-style: solid;
  transition: all .4s ease;
  background: #f7f7f7;
  border-color: rgb(158,158,158,.2);
  height: 537px;
  overflow: hidden;
}
.articles:hover .arrow-read-more {
  opacity: 0;
  transform: translateX(100%);
}
.arrow-read-more {
  position: absolute;
  right: 10px;
  bottom: 22px;
  transform: translateX(0);
}
.arrow-read-more, .arrow-read-more-2 {
  overflow: hidden;
  transition: all .2s ease;
  
}
.arrow-read-more svg, .arrow-read-more-2 svg {
  width: 22px;
  height: 22px;
}
.arrow-read-more svg {
  fill: rgba(16,17,24,.2);
  color: rgba(16,17,24,.2);
}
.articles:hover .arrow-read-more-2 {
  transform: translateX(0);
  opacity: 1;
  color: #2b2d42;
}
.articles .arrow-read-more-2:hover {
  color: #f64442;
}
.arrow-read-more-2 {
  position: absolute;
  right: 10px;
  bottom: 26px;
  transform: translateX(-100%);
  opacity: 0;
  font-weight: 700;
  transition: all .3s ease;
}
/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.ttt img {
    max-width: 197px;
    width: auto;
    height: 267px;
    overflow: hidden;
    object-fit: cover;
    object-position: center top;
    margin: 0 auto;
    display: block;
}
.ttt2{
  position: absolute;
  opacity: 0;  
  bottom: 37px;
  background-color: white;
  padding: 17px;
  max-width: 217px;
  left: 0;
  right: 0;
  margin: 0 auto;
  box-shadow: 3px 2px 7px #e2dede;
  border-radius: 7px;
}
.ttt:hover .ttt2{
  opacity: 1;
}
.bg-lines {
  position: absolute;
  height: 100%;
  left: 20px;
  right: 20px;
  top: 7%;
}
.bg-lines div:nth-child(1) {
  display: none;
}
.bg-lines div {
  background: rgba(0,0,0,.06);
  width: 1px;
  height: 100%;
  position: absolute;
}
.bg-lines div:nth-child(2) {
  margin-left: 23%;
}
.bg-lines div:nth-child(3) {
  margin-left: 49%;
}
.bg-lines div:nth-child(4) {
  margin-left: 73%;
}
.bg-lines div:nth-child(5) {
  margin-left: 100%;
}
.ttt .date-published {
  right: calc(100% - -2.5em);
}
.ttt .post-thumb:before {
  left: -40px;
  transition: all .4s ease;
}
.ttt:hover .post-thumb:before {
  background: rgb(255 71 71);
}
.ttt3 img {
  max-width: 227px;
  height: 227px;
}
.ttt4 .heading.price {
    top: 7px;
    right: 17px;
}
.ttt4 h2 {
  font-size: 23px;
}
.ttt6 .booksrec2 img {
  margin: 0px auto;
  max-width: 240px;
  max-height: 137px;
}
.ttt6 .booksrec2 {
  height: 137px;
  overflow: hidden;
}
.ttt6 h2 {
  font-family: "Signika",serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 33px;
  margin-bottom: 17px;
}
.ttt6 .aaa3 {
  padding: 37px;
}
.ttt5 .videosss2 {
  width: 387px;
  height: 247px;
  margin: 27px auto 27px;
}
.ttt6 .base-btn1 {
  margin-top: 0px;
  font-size: 17px;
  font-weight: 700;
  padding: 10px 30px;
  display: table;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 auto;
}
/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.search .book-wrapper img {
  max-width: 177px;
  height: 177px;
}
.search .stansss h4 {
  margin: 7px 0px 17px 10px;
  font-size: 20px;
  max-height: 100%;
  line-height: 37px;
}
.search .stansss {
  height: 267px;
}
.search .stansss p {
  margin: 30px 0 0px 0;
  font-size: 20px;
}
.search .stansss h6 {
  font-size: 20px;
}
.search .description {
  float: left;
  margin-top: 0;
  width: 100%;
  text-align: left;
  border-bottom: 1px solid #e6e6fa;
  margin-bottom: 27px;
  padding-bottom: 17px;
  height: 247px;
  overflow: hidden;
}
.search .active {
  color: #fd6500!important;
}
.searchbar {
  margin: 17px auto 47px;
  position: relative;
  display: table;
}
.search .searchbar input {
  font-size: 23px;
  width: 700px;
  height: 5rem;
  border: none;
  outline: none;
  border-radius: 10rem;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  padding: 2rem;
  transition: all .2s;
  transition-delay: .1s;
  font-family: Roboto,sans-serif;
}
.search .searchbar .btnmmm {
  height: 2.7rem;
  position: absolute;
  top: 7px;
  right: 17px;
  transition: all .2s;
  transition-delay: .1s;
  border: 0;
  padding: 17px;
  background-color: #f67d4100;
  color: #f67d41;
  font-size: 23px;
  outline: none;
}
.search .searchbar .btnmmm img {
  height: 20px;
  top: 1.5rem;
  right: 1rem;
  transition: all .2s;
  transition-delay: .1s;
}
.search .searchbar .btnmmm:hover {
  color: #34465e;
}
.search {
  float: left;
  width: 100%;
  background: #ffffff;
  min-height: 100vh;
}
.search .nav-tabssss li {
  color: #000;
  white-space: nowrap;
  border: 0px solid #e6e6fa;
  padding: 17px;
  width: 76%;
  background-color: #fbfbfb;
  display: block;
  margin-bottom: 17px;
  border-radius: 7px;
  font-weight: 600;
  font-size: 17px;
  box-shadow: 1px 1px 4px gainsboro;
}
.set-title h5 {
  border-bottom: 1px dashed #cecece;
  padding-bottom: 10px;
  margin-bottom: 27px;
  color: #ff4747;
  font-size: 27px;
  font-weight: 600;
}
.search .stansss h4 {
  margin: 7px 0 17px 10px;
  font-size: 20px;
  max-height: 100%;
  line-height: 37px;
  overflow: hidden;
  color: #000;
  font-weight: 400;
}
.search .stansss h6 {
  font-size: 20px;
  margin: 0 auto 7px 10px;
  text-align: left;
  color: #ff4747;
  font-weight: 300;
  line-height: 27px;
}
.search .booksrec4 {
  position: relative;
  overflow: hidden;
  float: left;
  width: 100%;
}
.search .booksrec4 img {
  max-height: 227px;
  overflow: hidden;
  margin: 0 auto;
  display: block;
  box-shadow: -3px 1px 6px #5f5d5d;
  border-radius: 10px;
}
.search .videosss2 {
  width: 92%;
  height: 207px;
  margin: 27px auto 17px;
  display: block;
  position: relative;
  box-shadow: 0 8px 16px -6px, 0 0 16px -6px;
  color: #000;
  border-radius: 10px;
}
.search .booksrec2 img {
  width: 117px;
  height: 117px;
  object-fit: contain;
  object-position: center center;
  margin: 0 0 7px;
  display: block;
  box-shadow: 0 0 3px #777570;
}
.search .single-btn2 {
  margin-left: 17px;
  max-height: 147px;
  overflow: hidden;
  margin-top: 3px;
}
.search .description h6 {
  color: #000;
  font-size: 20px;
  margin-bottom: 17px;
  height: 50px;
  overflow: hidden;
}
.audiosssbbb h4, .cccgggsss2 {
  overflow: hidden;
  text-align: center;
  color: #000;
}
.audiosssbbb h4 {
  margin: 0 auto 13px;
  font-size: 17px;
  max-height: 60px;
  width: 87%;
  float: none;
  line-height: 27px;
}
.audiosssbbb h6 {
  color: #397101;
  text-align: center;
  margin-bottom: 10px;
  font-family: Roboto,sans-serif;
  font-size: 17px;
}
.cccgggsss2 {
  height: 40px;
  margin: 0 auto;
  display: block;
  width: 100%;
  padding-top: 13px;
}

.ttt7 .menu-item {
  float: left;
  margin-right: 17px;
}
.ttt7 .menu-item a {
    color: #ffffff;
    font-size: 20px;
    text-transform: capitalize;
}
.ttt8{
  position: relative;
  width: 100%;
  float: left;
  z-index: 7;
  margin-top: 47px;
  margin-bottom: 27px;
}
.ttt8 .ttt8s:hover img{
  margin: 0px 10px 20px 0px;
}
.ttt8 .ttt8s:hover span{
  opacity: 1;
}
.ttt8s {
  display: block;
  position: relative;
}

.ttt8s span{
  opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    top: -63px;
    font-size: 17px;
    background-color: white;
    color: #f64442;
    padding: 17px;
    transition: .30s;
}
.ttt8s img {
  width: 66px;
  height: 66px;
  overflow: hidden;
  object-fit: cover;
  object-position: center top;
  border-radius: 100%;
  margin: 0px 10px 20px 0px;
  z-index: 10;
  transition: .30s;
}
.menu-itemcopyright .svg-inline--fa {
  color: #f64442;
}
.topslider5:before{
  content: "";
  position: absolute;
  height: 100%;
  top: -47px;
  left: 0;
  width: 100%;
  background: url('./Img/bg-top-3.webp') ;
  background-size: contain;
}
.desc23sss{
  height: 130px;
  overflow: hidden;
  font-size: 16px;
  line-height: 30px;
  color: #444;
  font-family: "Lato",sans-serif;
  margin-bottom: 0;
  font-style: normal;
  background-color: rgb(255 255 255 / 0%)!important;
}
.desc23sss p{
  background-color: rgb(255 255 255 / 0%)!important;
  text-align: center;
}
.desc23sss span{
  background-color: rgb(255 255 255 / 0%)!important;
  text-align: center;
}
/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.central-metasearch {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #ede9e9;
  border-radius: 5px;
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
  padding: 37px 17px 27px;
  position: relative;
}
.eee {
  padding: 0;
  margin-top: 37px;
}
.eee2 {
  padding: 0;
  margin-top: 37px;
}
.eee3 {
  padding: 0;
  margin-top: 37px;
}
.eee img{
  max-width: 160px;
  margin: 27px 0 17px 0;
}
.eee2 img{
  max-width: 260px;
  margin: 27px 0 17px 0;
}
.eee3 img{
  max-width: 160px;
  margin: 27px 0 17px 0;
}
.eee h2, .eee2 h2, .eee3 h2{
  position: relative;
  color: #ff4747;
  line-height: 1.2em;
  font-weight: 900;
  font-family: 'Signika', serif;
  margin-bottom: 17px;
  font-size: 37px;
}
.eee .videosss2 {
  width: 460px;
  height: 300px;
}
.lll .book-wrapper2 img {
  box-shadow: -1px 1px 10px #807f7f;
  margin: 10px auto 17px;
  display: block;
  max-width: 207px;
  height: 277px;
  transition: .3s;
  border-radius: 10px;
}
.lll h1 {
  color: #5a3733;
  text-align: center;
  line-height: 1.2em;
  font-weight: 900;
  font-family: "Signika",serif;
  margin-bottom: 17px;
  font-size: 37px;
}
.ttt7{
  margin: 0 auto;
}
/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.publsssqqq{
  position: relative;
  padding: 0px 0 107px 0;
  width: 100%;
  float: left;
}
.publsssqqq h1 {
  color: #5A3733;
  text-align: center;
  line-height: 1.2em;
  font-weight: 900;
  font-family: 'Signika', serif;
  font-size: 47px;
  margin-bottom: 37px;
}
.publsssqqq .aaa3 {
  box-shadow: 0 20px 40px 0 rgba(28,30,35,0.2);
  border: 0px solid #f0f2f3;
  padding: 30px 30px;
}
.sfdfgsss img {
  max-width: 197px;
  max-height: 137px;
}
.sfdfgsss h6 {
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  margin: 27px auto 17px;
  display: block;
  height: 57px;
  overflow: hidden;
  font-family: "Lato",sans-serif;
  color: #ff4747;
}
.sfdfgsss .desc23sss {
  height: 127px;
}
.news-block-one .subcolor-main{
  display: none;
}

ul.main-menu{white-space: nowrap;}
.timeline{white-space: nowrap;}
.ttt7{white-space: nowrap;}
.copyright-block p{white-space: pre-wrap;}
.base-btn1{white-space: nowrap;}
.btn{white-space: nowrap;}
.thm-btn{white-space: nowrap;}
label{white-space: nowrap;}
.search .nav-tabssss li {
  cursor: pointer;
}





@media(max-width:480px){
  .ttt5 .videosss2 {width: 260px;height: 207px;}
  .ttt3 img {max-width: 203px;height: 227px;}
  .memberstabsss li {padding: 10px;font-size: 13px;margin: 0 7px 10px 7px;}
  .zzz8s .zzz7 {overflow: hidden;margin-top: 17px;margin-left: 13px;margin-right: 17px;}
  .zzz7 p {word-break: break-all;}



}





@media(max-width:767px){
  .addbook .direction-r {white-space: break-spaces;}
  .ttt6 h2{font-size: 20px!important;}
  .members {padding: 37px 0 37px 0;}
  .myaccount-content {font-size: 25px;}
  .profileimgsss {width: 127px;min-width: 127px;min-height: 127px;height: 127px;}
  .header-middle img {margin: 0 auto;display: block;}
  .res5c {padding: 6px 10px 6px 10px;margin: 7px auto;}
  .aaa {display: none;}
  .topslider {padding: 33px 0 32px 0;}
  .topslider4 {padding: 33px 0 47px 0;position: relative;}
  .topslider4 h1 {margin-bottom: 7px;font-size: 33px;}
  .book2 { transform: scale(1.0);}
  .videosss2 {width: 337px;height: 247px;margin: 17px auto 0px;}
  .topslider2 {padding: 47px 0px 37px 0px;}
  .topslider6 {padding: 33px 10px 33px 10px;}
  .topslider3 {padding: 33px 0 67px 0;}
  .topslider5:before {opacity: 0;}
  .ttt7 {padding: 0 17px 0 17px;}
  .ttt8 {display: none;}
  .menu-itemcopyright {right: 0;left: 0;top: -30px;}
  .wpb_single_image.vc_align_right {display: none;}
  .menuq2 { width: 90%;margin: 0 auto;padding: 17px;border-top: 4px solid #ff4747;box-shadow: 1px 1px 7px #cccccc;}
  .menu-item {display: block;clear: both;margin-bottom: 17px;}
  .menuq2 .sub-menu{ width: 90%;margin: 17px auto;padding: 17px;border-top: 4px solid #ff4747;box-shadow: 1px 1px 7px #cccccc;}
  .sub-menu .menu-item a {color: #000000;}
  .addbook h3 {line-height: 37px;margin-bottom: 17px;font-size: 22px;}
  .addbook .thm-btn.lsss { left: 0;}
  .addbook .timeline { margin: 67px 0 67px 27px;}
  .addbook .direction-lsss {position: relative;top: 0;left: 25px;}
  .addbook .timeline li { padding: 0em 0;}
  .addbook .thm-btn.rsss {left: 0;}
  .bookads2 .central-meta {padding: 17px;}
  .timeline {width: 243px;}
  .direction-r {width: 188px;}
  .direction-l { width: 187px;}
  .timeline:before {left: 0;}
  .addbook .direction-lsss {z-index: 10000;}
  .bg-lines {display: none;}
  .addbook .flag {font-size: 23px;}
  .currency { font-size: 27px;}
  .value {font-size: 27px;}
  .pagebooks2 h2 {margin-bottom: 27px;font-size: 37px;}
  .pagebooks {padding: 37px 0 37px 0;}
  .ttt7 .menu-item { clear: unset; margin-bottom: 7px;}
  .bookads2 {padding: 37px 0 37px 0;}
  .yyy {padding-right: 0;}
  .thm-btn {padding: 7px 10px 7px 10px;font-size: 16px;}
  .search .searchbar input {width: 100%;}
  

}
@media(min-width:768px) and (max-width:991px){
  .book-wrapper img{max-width: 177px; width: auto;height:257px}
  .topslider2 .videosss2 { width: 560px!important;height: 377px!important;}
  .bookads2 .col-md-8 {width: 100%; display: block;max-width: 100%;flex: 0 0 100%;margin-top: 17px;}.heading.color-white .header {font-size: 27px;}
  .topslider6 .book-wrapper2 img {margin: 0px auto 7px;max-width: 127px;height: 180px;}
  .ttt .date-published {right: calc(100% - -1.5em);}
  .date-published span.big {font-size: 17px;}
  .ttt6 .booksrec2 img {max-width: 167px;max-height: 97px;}
  .ttt6 .booksrec2 {height: 97px;}
  .ttt6 .aaa3 {padding: 10px;}
  .ttt .post-thumb:before {left: -24px;}
  .aaa6 {width: 100%; display: block;max-width: 100%;flex: 0 0 100%;margin-top: 17px;}
  .memberstabsss li {padding: 10px;}
  .ttt6 h2{font-size: 20px!important;}
  .members {padding: 37px 0 37px 0;}
  .myaccount-content {font-size: 25px;}
  .profileimgsss {width: 127px;min-width: 127px;min-height: 127px;height: 127px;}
  .header-middle img {margin: 0 auto;display: block;}
  .res5c {padding: 6px 10px 6px 10px;margin: 17px auto;display: block!important;float: right;}
  .aaa {display: none;}
  .topslider {padding: 33px 0 32px 0;}
  .topslider4 {padding: 33px 0 47px 0;position: relative;}
  .topslider4 h1 {margin-bottom: 7px;font-size: 33px;}
  .book2 { transform: scale(1.0);}
  .videosss2 {width: 247px!important;height: 177px!important;margin: 17px auto 0px;}
  .topslider2 {padding: 47px 0px 37px 0px;}
  .topslider6 {padding: 33px 10px 33px 10px;}
  .topslider3 {padding: 33px 0 67px 0;}
  .topslider5:before {opacity: 0;}
  .ttt7 {padding: 0 17px 0 17px;}
  .menu-itemcopyright {right: 0;top: 20px;}
  .copyright-block .ttt8s{flex: 0 0 33.333333%!important;max-width: 33.333333%!important;}
  .wpb_single_image.vc_align_right {display: none;}
  .menuq2 { width: 90%;margin: 0 auto;padding: 17px;border-top: 4px solid #ff4747;box-shadow: 1px 1px 7px #cccccc;position: absolute; right: 0;top: 70px;z-index: 1000;background-color: white;}
  .menu-item {display: block;clear: both;margin-bottom: 17px;}
  .menuq2 .sub-menu{ width: 90%;margin: 17px auto;padding: 17px;border-top: 4px solid #ff4747;box-shadow: 1px 1px 7px #cccccc;}
  .sub-menu .menu-item a {color: #000000;}
  .addbook h3 {line-height: 37px;margin-bottom: 17px;font-size: 30px;}
  .addbook .thm-btn.lsss { left: 87px;}
  .addbook .timeline { margin: 67px 0 67px 27px;}
  .addbook .direction-lsss {top: -17%;left: 25px;}
  .addbook .timeline li { padding: 0em 0;}
  .addbook .thm-btn.rsss {left: 87px;}
  .addbook .timeline:before {left: 27%;}
  .addbook .direction-r {left: 210px;}
  .bookads2 .central-meta {padding: 17px;}
  .timeline {width: 400px;}
  .direction-r {width: 170px;}
  .direction-l { width: 170px;}
  .flag {padding: 10px 17px 12px 17px;}
  .addbook .col-md-7{width: 100%; display: block;max-width: 100%;flex: 0 0 100%;overflow: hidden;} 
  .search  .col-md-11{width: 100%; display: block;max-width: 100%;flex: 0 0 100%;overflow: hidden;} 
  .addbook .direction-lsss {z-index: 10000;}
  .bg-lines {display: none;}
  .addbook .flag {font-size: 23px;}
  .currency { font-size: 27px;}
  .value {font-size: 27px;}
  .pagebooks2 h2 {margin-bottom: 27px;font-size: 37px;}
  .pagebooks {padding: 37px 0 37px 0;}
  .ttt7 .menu-item { clear: unset; margin-bottom: 7px;}
  .bookads2 {padding: 37px 0 37px 0;}
  .yyy {padding-right: 0;}
  .thm-btn {padding: 7px 10px 7px 10px;font-size: 16px;}
  .listing-duration {max-width: 467px;}
  .ttt img {max-width: 137px;height: 177px;}
  .inner-box .app {margin-bottom: 0px;}
  .zzz8 img {max-width: 143px;}
  .yyy h1 {font-size: 20px;line-height: 30px;}
  .profileimgsssbooks { width: 127px;max-width: 127px;max-height: 127px;min-width: 127px;min-height: 127px;height: 127px;}
  .lll .book-wrapper2 img {max-width: 137px;height: 187px;}
  .lll {padding: 0;}
  .uuu h2 {font-size: 27px;}
  .news-block-one .base-btn1 {padding: 10px 27px;}
  .sfdfgsss img {max-width: 107px;max-height: 107px;margin: 0px auto;}
  .publsssqqq .aaa3 {padding: 10px 7px;}
  .post-thumb {max-width: 100%;padding-left: 17px;padding-right: 10px;}
  .post-thumb:before {left: -10px;}
  .aaa{display: none;}
  .date-published {right: calc(100% - -0.5em);}
  .topslider5 img {max-width: 137px;height: 200px;}
  .post-title {max-width: 100%;}
  .search .nav-tabssss li {padding: 10px;width: 97%;}
  .search .stansss h4 {margin: 0px 0 7px 10px;font-size: 17px;max-height: 100%;line-height: 23px;}
  .search .base-btn1 {padding: 10px 13px;}
  .search .booksrec4 img {max-height: 117px;}
  .search .videosss2 {width: 223px!important; height: 167px!important;}
  .search .booksrec2 img {max-width: 96px;width: 100%;height: 60px;object-fit: contain;}


}


@media (min-width:1500px) {
  .desc2 span{ font-size: 17px!important; }
  .desc23sss span{ font-size: 17px!important; }


}



@media (min-width:1200px) and (max-width:1300px){
  .header-middle img {max-width: 190px;}
  ul.main-menu>li.menu-item>a { margin-left: 10px!important;margin-right: 3px!important;}
  ul.main-menu>li.menu-item:first-child a {margin-left: 0px!important;} 
  ul.main-menu>li.menu-item:last-child a{ margin-right: 0px!important;}
  .ttt7 .menu-item a {font-size: 17px;}
  .timeline {width: 437px;}
  .direction-r {width: 188px;}
  .direction-l { width: 187px;}
  .ttt3 img {max-width: 217px;height: 217px;}

}
@media (min-width:900px) and (max-width:991px){
  .booksrec4 img {max-height: 137px!important}
}
@media (min-width:992px) and (max-width:1199px){
  .search .booksrec4 img {max-height: 137px;}
  .search .booksrec2 img {width: 93px;height: 93px;}
  .header-middle img {max-width: 147px;}
  ul.main-menu>li.menu-item>a { 
    margin-left: 7px!important;margin-right: 0px!important;font-size: 15.7px;}
  ul.main-menu>li.menu-item:first-child a {margin-left: 0px!important;} 
  ul.main-menu>li.menu-item:last-child a{ margin-right: 0px!important;}
  .ttt7 .menu-item a {font-size: 17px;}
  .has-children .svg-inline--fa.fa-w-14 {display: none;}
  ul.main-menu>li .sub-menu .menu-item a {font-size: 16px;}
  .ttt7 .menu-item {margin-right: 7px;}
  .book-wrapper img{max-width: 172px;height: 257px;}
  .booksrec4 img {max-height: 180px;}
  .sfdfgsss img {max-width: 147px;}
  .topslider5:before {top: -27px;}
  .timeline {width: 437px;}
  .direction-r {width: 188px;}
  .direction-l { width: 187px;}
  .ttt5 .videosss2 {width: 323px; height: 217px;}
  .addbook .direction-lsss {left: -30%;}
  .zzz8 img {max-width: 177px;}
  .lll .book-wrapper2 img {max-width: 162px;height: 217px;}
  .lll{padding: 0;}
  .search .stansss h4 {margin: 7px 0 10px 10px;font-size: 17px;line-height: 23px;}

}


.disllqqqsss{display: none;}
.renewtext{
  font-size: 18px;
  margin-bottom: 47px;
}

.ois__sharsss {
  display: block;
  float: left;
  width: 100%;
}
.ois__sharsss button {
  margin-left: 7px;
  margin-right: 7px;
}
.ois__sharsss h2 {
  display: inline-block;
  font-size: 20px;
  margin-right: 10px;
}
.ois__sharsss h2svg{
  font-size: 17px;
  margin-right: 7px;
  vertical-align: middle;
}
.sqw__listsss{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.login__checkbox {
 display: flex;
 align-items: center;
 font-size: 18px;
 word-break: break-word;
 width:100%;
 gap: 10px;
}
.login__checkbox2 {
  width:27px;
  height:27px;
  color:blue;
  cursor: pointer;
}

.aboutssss2 {
  background-color: #fff;
  border: none;
  border-radius: 6px;
  box-shadow: 0 4px 18px 0 rgba(194, 200, 213, .3);
  margin: 0 0 30px;
  padding: 47px;
}
.aboutssss2 h3 {
  font-size: 26px;
  font-weight: 500;
  margin-top: 17px;
  margin-bottom: 17px;
}

.aboutssss2 h1, .aboutssss2 h2 {
  margin-bottom: 17px;
}

.aboutssss2 p, .aboutssss2 ul li {
  color: #635d5d;
  font-size: 18px;
  line-height: 1.8;
  margin-bottom: 7px;
}
.aboutssss2 ul {
  box-shadow: 0 3px 7px 0 rgba(28, 30, 35, .07);
  list-style: none;
  margin-bottom: 37px;
  margin-left: 24px;
  padding: 27px;
}
.aboutssss2 p, .aboutssss2 ul li {
  color: #635d5d;
  font-size: 18px;
  line-height: 1.8;
  margin-bottom: 7px;
}